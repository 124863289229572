import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { LeadService } from '../../services/lead.service';
import { catchError, map, merge, startWith, switchMap } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { FormControl, NgForm } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { UsersService } from '../../services/users.service';
import { TrackingService } from '../../services/tracking.service';

@Component({
  selector: 'app-lead',
  templateUrl: './lead.component.html',
  styleUrls: ['./lead.component.scss'],
})
export class LeadComponent {
  isCenter:boolean = false;
  btnUpdateLoading:boolean = false;
  center_id: any;
  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  isCron: any;
  isDivVisible = true;
  isReminderChecked:boolean = true;
  btnLoading: boolean = false;
  pageSizeOptions: number[] = [10, 25, 50];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  searchQuery = new FormControl();
  dateQuery = new FormControl();
  currentTab = 'all_leads';
  followupLoading = false;
  formAction = 'add';
  closeResult = '';
  isSubmitted: boolean = false;
  displayedColumns: string[] = [];
  displayedLebel: any = {};
  exportColumns: string[] = [];
  leadsRecord: any = [];
  infoArray: any = [];
  dateObject: any = { startDate: '', endDate: '' };
  lead: Lead = new Lead();
  leadFolloup: LeadFollowup = new LeadFollowup();
  @ViewChild('leadForm')
  leadForm!: NgForm;
  @ViewChild('leadFolloupForm')
  leadFolloupForm!: NgForm;
  leadsFollowList: any = [];
  leadChannels: any = [];
  users: any = [];
  mergedArr: any = [];
  exportArrayData: any = [];
  preUserId: any = '';
  LeadArrayData = [];
  temp: any = [];
  subsciberListName: any = '';
  customer = false;
  allLeadFeilds: any = [];
  filterArray: any = [];
  copyOfFields: any = {};
  deleteParam: any = {};
  isAdmin: any = false;
  exportDataMap: any = {};
  isExportLoading: boolean = false;
  isFixedColumn:boolean = false;
  isGetLocation:boolean = false;
  createFieldFormValue: any = {
    fields: {},
    lead_date: '',
    lead_time: '',
  };
  topPosition: any;
  leftPosition: any;
  hoveredRow: any;
  current_id_hover:any;
  fieldValues: any = {};
  editFieldValues: any = {};
  remarkValue: any = '';
  notification_date: any = '';
  next_action: any = '';
  editShow: boolean = false;
  defaultSort = '"leadsrecords"."updatedAt"'
  accessObject:any = {}
  uploadBtnLoading:boolean = false;
  leadSpaceToggle:boolean = false;
  leadSpaceList:any = [];
  leadSpaceName:any;
  currentSpace_id:any;
  idToDelete:any;
  idToEdit:any;
  nameLeadSpaceToEdit:any;
  currentActiveTabId:any;
  initialLoad:boolean = true;
  leadToMove:any;
  selectedLeadSpace:any;
  @ViewChild('AllLeadsTab', { static: true })
  AllLeadsTab!: ElementRef;

  constructor(
    private toast: ToastrService,
    private api: LeadService,
    private modalService: NgbModal,
    private localStorage: LocalStorageService,
    private userApi: UsersService,
    private tracker:TrackingService,
    private eRef: ElementRef, private renderer: Renderer2
  ) {
    this.isCenter = localStorage.getIsCenter();
    this.isFixedColumn = localStorage.getIsFixedCol();
    this.isGetLocation = localStorage.getIsGetLocation();
    //alert(this.isFixedColumn)
    if(this.isCenter){
      this.center_id = localStorage.getCenterId();
    }
    this.leadChannels = JSON.parse(
      JSON.stringify(this.localStorage.getLeadChannels())
    );
    this.isCron = this.localStorage.getCron();
    this.isAdmin = this.localStorage.checkFullAccessForTableList(
      this.localStorage.getRole()
    );
   // this.tracker.track("View","Leads","User Viewed:Lead Listing",null)
  }
  toggleLeadSpace(){
    this.leadSpaceToggle = ( this.leadSpaceToggle) ? false : true;
  }
  createLeadSpace(){

    this.btnLoading = true;
    if(this.leadSpaceName!=""){
      let param =  {
        client_id:this.localStorage.getClientId(),
        space_name:this.leadSpaceName
      }
     this.api.createLeadSpace(param).subscribe({
      next:(res)=>{
        if(res){
          this.getLeadSpace()
          setTimeout(() => {
            this.leadSpaceName = "";
            this.leadSpaceToggle = false;
          this.toast.success("Lead Space Created Successfully")
          this.btnLoading = false;
          },1000)
        }
      },error:(e)=>{
        this.btnLoading = false;
      }
     })
    }
   
  }
  getLeadSpace(name:any = null){
    let param =  {
      where:{
        client_id:this.localStorage.getClientId()
      }
 
    }
   this.api.getAllLeadSpace(param).subscribe({
    next:(res)=>{
      if(res){
        this.leadSpaceList = res;

        if(name){
          this.currentTab = name;
        }
      }
    },error:(e)=>{
    }
   })
  }
  ngAfterViewInit() {
    //console.log('sdf');
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.getLeadsFields();
    this.getLeadSpace();
    this.currentTab = 'all_leads';
    this.loadData();
  }
  ngOnInit(): void {
    this.accessObject = this.localStorage.checkCRUDAccess('lead','manual')
  }
  // deleteOpen(id:any) {
  //  this.currentSpace_id =  id;
  // }

  onClickedOutside() {
    console.log('Clicked outside the element!');
    // Handle the click outside event
  }
  hideSubFields(item:any){
   // console.log(item)
    if(item.includes('subf')) 
      return false
    else
    return true
  }
  onFileChange(evt: any) {
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = <any[][]>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
     // console.log(data);
    };
    reader.readAsBinaryString(target.files[0]);
  }
  isValidEmail(email: string, form: any, key: any): boolean {
    const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      form.controls[key].setErrors({ invalid: true });
      return false;
    } else {
      form.controls[key].setErrors(null);
      return true;
    }
  }

  scrollTableTo(direction: any) {
    const el: any = document.querySelector('.table-container');
    if (direction === 'right') {
      el.scrollBy({
        left: el.offsetWidth,
        behavior: 'smooth',
      });
    } else if (direction === 'left') {
      el.scrollBy({
        left: -el.offsetWidth,
        behavior: 'smooth',
      });
    }
  }

  addMoreSubField(id: any, item: any) {
    let inc = item.sub_fields.length;
    const add = this.copyOfFields[item.field_name];

    // item.sub_fields.forEach((element: any) => {
    //   this.fieldValues[item.field_name + '|subf'].push({
    //     [element.field_name + '|' + inc]: '',
    //   });
    //   inc++;
    // });
    // this.allLeadFeilds[id].sub_fields.push(
    //   ...this.allLeadFeilds[id].sub_fields
    // );
    for (let i = 0; i < add; i++) {
      this.fieldValues[item.field_name + '|subf'].push({
        [item.sub_fields[i].field_name + '|' + inc]: '',
      });
      inc++;
    }
    this.allLeadFeilds[id].sub_fields.push(
      ...this.allLeadFeilds[id].sub_fields.slice(0, add)
    );
  }

  removeSubField(id: any, item: any, subInd: any) {
    const popLen = this.copyOfFields[item.field_name];
    this.fieldValues[item.field_name + '|subf'].splice(subInd, popLen);
    this.allLeadFeilds[id].sub_fields.splice(subInd, popLen);

    let inc = 0;
    this.fieldValues[item.field_name + '|subf'].forEach((element: any) => {
      const val = element;
      const preKey = Object.keys(element)[0];
      const preKeyVal = preKey.split('|');
      this.fieldValues[item.field_name + '|subf'][inc] = {
        [preKeyVal[0] + '|' + inc]: val[preKey],
      };
      inc++;
    });
  }

  getEditButton(id: any) {
    if (
      this.localStorage.checkFullAccessForTableList(this.localStorage.getRole())
    ) {
      return true;
    } else {
      if (id == this.localStorage.getUserId()) {
        return true;
      } else {
        return false;
      }
    }
  }

  handleStoreCurrentSpaceId(id: any, open : boolean = false) {
    if(this.currentActiveTabId == id){
      this.currentSpace_id = null;
      return
    }
    // male data empty
    this.dataSource.data = [];
    this.currentActiveTabId = id;
    this.isDataLoading = true;
    this.paginator.pageIndex = 0;
    this.loadDataOnTabs(id);

    if(!open){
    this.currentSpace_id = null;
    }else{
      this.currentSpace_id = id;
    }

  }
  //get all user
  getAllUsers() {
    let param = {
      client_id: this.localStorage.getClientId(),
    };
    this.userApi.findAllUsers(param).subscribe({
      next: (res: any) => {
        this.users = res || [];
      },
      error: (e: any) => {
        console.error(e);
      },
    });
  }

  // get value
  getValue(value: any, item: any, subInd: any) {
    let subflen = item.sub_fields.length;
    let accessIndex = subInd % subflen;
    return item.sub_fields[accessIndex][value];
  }

  //getARYA
  getArray(item: any) {
    return this.editFieldValues['fields'][item.field_name + '|subf'];
  }
  // my edit
  getLeadsFields() {
    // id = this.localStorage.getClientId()
    let param = {
      client_id: this.localStorage.getClientId(),
    };
    this.api.getLeadsFields(param).subscribe({
      next: (data) => {
        this.displayedColumns = [];
        this.allLeadFeilds = data.fields.sort((a:any, b:any) => a.sequence - b.sequence);

        this.displayedColumns.push('info');
        if(this.isAdmin){
          this.displayedColumns.push('assign_to');
        }
    
        if(this.isFixedColumn){
          this.displayedColumns.push('contact_name');
          this.displayedColumns.push('contact_email');
          this.displayedColumns.push('contact_phone');
          this.displayedColumns.push('company_name');
          this.displayedColumns.push('lead_source');
          this.exportColumns.push('Name');
          this.exportColumns.push('Email');
          this.exportColumns.push('Contact');
          this.exportColumns.push('Organisation');
          this.exportColumns.push('Source');
          this.exportDataMap['Name'] = 'contact_name';
          this.exportDataMap['Organisation'] = 'company_name';
          this.exportDataMap['Email'] = 'contact_email';
          this.exportDataMap['Contact'] = 'contact_phone';
          this.exportDataMap['Source'] = 'lead_source';
        }
        data.fields.forEach((element: any) => {
          
          this.infoArray.push({key:element.field_name,value:element.label});
          if(element.isVisibleTable == 'true') {
            this.displayedColumns.push(element.field_name);
          }
        
          this.displayedLebel[element.field_name] = element.label;
          this.filterArray.push({
            key: element.field_name,
            value:
              element.field_name.charAt(0).toUpperCase() +
              element.field_name.slice(1),
          });
        });
        this.filterArray.push({ key: 'lead_time', value: 'Time' });
        this.filterArray.push({ key: 'lead_type', value: 'Lead Type' });
        this.filterArray.push({ key: 'lead_status', value: 'Lead Status' });
        this.filterArray.push({ key: 'assign_to', value: 'Lead Owner' });
        this.exportColumns = [];
        this.exportDataMap = {};
        data.fields.forEach((element: any) => {
          this.exportColumns.push(element.label);
          this.exportDataMap[element.label] = element.field_name;
          if (element.sub_fields && element.sub_fields.length > 0) {
            this.fieldValues[element.field_name + '|subf'] = [];
          }
          let inc = 0;
          if (element.sub_fields && element.sub_fields.length > 0) {
            this.copyOfFields[element.field_name] = element.sub_fields.length;
            element.sub_fields.forEach((sub: any) => {
              this.exportColumns.push(sub.label);
              this.exportDataMap[sub.label] = sub.field_name;
              this.fieldValues[element.field_name + '|subf'].push({
                [sub.field_name + '|' + inc]: '',
              });
              inc++;
            });
          }
        });
       
      


        this.displayedColumns.push('lead_type');
        this.displayedColumns.push('lead_status');
        this.displayedColumns.push('action');
        this.exportColumns.push('Lead Date');
        this.exportColumns.push('Lead Time');
        this.exportColumns.push('Lead Type');
        this.exportColumns.push('Lead Status');
        this.exportColumns.push('Assign To');
        this.exportDataMap['Lead Date'] = 'lead_date';
        this.exportDataMap['Lead Time'] = 'lead_time';
        this.exportDataMap['Lead Type'] = 'lead_type';
        this.exportDataMap['Lead Status'] = 'lead_status';
        this.exportDataMap['Assign To'] = 'assign_to';
      },
      error: (e) => {
        console.error(e);
      },
    });
  }
  getStickyClass(index: number): string {
    const offset = 100 * index; // Adjust this value based on the actual width of your columns
    return `sticky-column sticky-left-${offset}`;
  }
  showInfo(row:any,event:any)
  {
    this.topPosition = 0;
    this.leftPosition = 0;
    this.hoveredRow = row;
    console.log("getPosition",event)
    this.topPosition =  event.layerY;
    this.leftPosition = event.layerX;
    this.current_id_hover = row.lead_id
  }
  onMouseMove(row:any,event: MouseEvent): void {
    this.current_id_hover =  row.lead_id;
  }
  openDialog(element:any)
  {

  }
  hideInfo()
  {
    this.current_id_hover = "";
  }

  searchFilter(query: any) {
    this.searchQuery.setValue(query);
  }
  ResetDateFilter(event: any) {
    this.dateQuery.setValue('');
  }
  ResetFilter(event: any) {
    this.searchQuery.setValue('');
  }
  clearleadForm() {
    this.lead = new Lead();
  }

  announceSortChange(evn: any) {
    // this.sort.sortChange.emit(evn);
  }

  // load Initial table data from based on source type
  loadData() {

 

    try {
      this.sort?.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      merge(
        this.dateQuery.valueChanges,
        this.searchQuery.valueChanges,
        this.sort.sortChange,
        this.paginator.page
      )
        .pipe(
          startWith({}),
          switchMap(() => {
           
          
            let param = {
              // where:{leadChannel:this.currentTab,hospital_id:this.localStorage.getHospitalId(),client_id: "1"},
              where: { client_id: this.localStorage.getClientId(),center_id: this.localStorage.getCenterId()},
              filter: this.searchQuery.value,
              page: this.paginator.pageIndex * this.paginator.pageSize,
              limit: this.paginator.pageSize,
              // startDate:this.dateQuery.value.startDate,
              // endDate:this.dateQuery.value.endDate,
              order: {
                col: this.sort.active == 'lead_date' ? 'lead_date' : this.sort.active == 'lead_time' ? 'lead_time' : this.sort.active == 'lead_type' ? 'lead_type' : this.sort.active == 'lead_status' ? 'lead_status' : this.sort.active == undefined ? '"leadsrecord"."updatedAt"' : 'fields->>' + "'" + this.sort.active + "'",
                dir: this.sort.direction || 'DESC',
              },
            };
            if (this.dateQuery.value) {
              param = { ...param, ...this.dateQuery.value };
            }
             console.log(this.localStorage.checkFullAccessForTableList(this.localStorage.getRole()))
            if (this.localStorage.checkFullAccessForTableList(this.localStorage.getRole())) 
              {
              param = param;
            } else {
              const obj = {
                ...param.where,
                center_id : this.center_id,
                user_id:this.localStorage.getUserId()
              };
              if(this.localStorage.getClientId()== "1") {
                delete obj.user_id
              }
              
              param.where = obj;
            }
            let assign_to;
           // console.log(this.searchQuery.value)
            if(this.searchQuery.value && this.searchQuery.value.hasOwnProperty('assign_to') ){
              assign_to = this.searchQuery.value['assign_to']
              //alert(assign_to)
              const obj = {
                ...param.where,
                user_id : assign_to,
              };
              param.where = obj;
              param.filter = {}
              //delete this.searchQuery.value['assign_to']
              
            }
            //alert(assign_to)
           // console.log(param)
            return this.api
              .getAllData(param)
              .pipe(
                catchError(() => observableOf(null)));
          }),
          map((response: any) => {
            if (response === null) {
              return [];
            }
            this.totalRows = response.count;
            return response;
          })
        )
        .subscribe({
          next: (data) => {
            data.rows.forEach((e: any, i: any) => {
              let index = this.temp.findIndex((p: any) => {
                if (p.lead_id === e.lead_id) {
                  data.rows[i].isSelected = true;
                }
              });
            });
            this.dataSource = new MatTableDataSource<any>(data.rows);
            this.initialLoad = false;
            this.isDataLoading = false;
          },
          error: (e) => {
            this.initialLoad = false;
            this.isDataLoading = false;
            console.error(e);
          },
          complete: () => {},
        });
    } catch (e) {
      console.error(e);
    }
  }

  isDataLoading:boolean = false;

  loadDataOnTabs(tabId: any) {

    

    try {
      this.isDataLoading  = true;
      this.sort?.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      merge(
        this.dateQuery.valueChanges,
        this.searchQuery.valueChanges,
        this.sort.sortChange,
        this.paginator.page
      )
        .pipe(
          startWith({}),
          switchMap(() => {
           
          
            let param = {
              // where:{leadChannel:this.currentTab,hospital_id:this.localStorage.getHospitalId(),client_id: "1"},
              where: { client_id: this.localStorage.getClientId(),center_id: this.localStorage.getCenterId(),
                lead_space_id: tabId
              },
              filter: this.searchQuery.value,
              page: this.paginator.pageIndex * this.paginator.pageSize,
              limit: this.paginator.pageSize,

              // startDate:this.dateQuery.value.startDate,
              // endDate:this.dateQuery.value.endDate,
              order: {
                col: this.sort.active == 'lead_date' ? 'lead_date' : this.sort.active == 'lead_time' ? 'lead_time' : this.sort.active == 'lead_type' ? 'lead_type' : this.sort.active == 'lead_status' ? 'lead_status' : this.sort.active == undefined ? '"leadsrecord"."updatedAt"' : 'fields->>' + "'" + this.sort.active + "'",
                dir: this.sort.direction || 'DESC',
              },
            };
            if (this.dateQuery.value) {
              param = { ...param, ...this.dateQuery.value };
            }

            if (
              this.localStorage.checkFullAccessForTableList(
                this.localStorage.getRole()
              )
            ) {
              param = param;
            } else {
              const obj = {
                ...param.where,
                center_id : this.center_id,
                user_id:this.localStorage.getUserId()
              };
              if(this.localStorage.getClientId()== "1") {
                delete obj.user_id
              }
              
              param.where = obj;
            }
            let assign_to;
           // console.log(this.searchQuery.value)
            if(this.searchQuery.value && this.searchQuery.value.hasOwnProperty('assign_to') ){
              assign_to = this.searchQuery.value['assign_to']
              //alert(assign_to)
              const obj = {
                ...param.where,
                user_id : assign_to,
              };
              param.where = obj;
              param.filter = {}
              //delete this.searchQuery.value['assign_to']
              
            }
            //alert(assign_to)
            console.log(param)
            return this.api
              .getAllData(param)
              .pipe(catchError(() => observableOf(null)));
          }),
          map((response: any) => {
            if (response === null) {
              return [];
            }
            this.totalRows = response.count;
            return response;
          })
        )
        .subscribe({
          next: (data) => {
            data.rows.forEach((e: any, i: any) => {
              let index = this.temp.findIndex((p: any) => {
                if (p.lead_id === e.lead_id) {
                  data.rows[i].isSelected = true;
                }
              });
            });
            this.dataSource = new MatTableDataSource<any>(data.rows);
            this.isDataLoading = false;
          },
          error: (e) => {
            console.error(e);
            this.isDataLoading = false;

          },
          complete: () => {},
        });
    } catch (e) {
      console.error(e);
    }
  }


  
 

  // HostListener to detect clicks outside the component
  // @HostListener('document:click', ['$event.target'])
  // onDocumentClick(event: Event): void {
  //   const clickedInside = this.eRef.nativeElement.contains(event.target);
  //   const specificElement = document.getElementById('action_tab_button'); // Replace with the class or ID of the element you want to track
  //   if(specificElement) {
  //     //this.isDivVisible = false
  //   }
  
  //   const clickedOnSpecificElement = specificElement?.contains(event.target as Node);
  //   console.log(clickedOnSpecificElement)
  //   if (!clickedInside && !clickedOnSpecificElement) {
  //     this.handleClickOutside();
  //   }
  // }
  hideDiv(){
    alert()
  }
  deleteOpen(spaceId: number): void {
   if(this.currentSpace_id == spaceId){
    this.currentSpace_id = null;
   }else{
    if(this.currentActiveTabId !== spaceId){
    this.handleStoreCurrentSpaceId(spaceId, true)
    }else{
      this.currentSpace_id = spaceId;
    }
   }
  }

  handleChangeLeadSpaceName(): void {
    this.btnLoading = true;
    // {
    //   "client_id":"",
    //   "id":"" -->tab_id,
    //   "space_name":"", -->updated tab name
    //   }

    const payload = {
      client_id: this.localStorage.getClientId(),
      id: this.idToEdit,
      space_name: this.nameLeadSpaceToEdit,
    };

    this.api.updateLeadSpace(payload).subscribe({
      next: (res:any) => {
        this.toast.success("Updated successfully");
        this.getLeadSpace(this.nameLeadSpaceToEdit);
        this.modalService.dismissAll();
        this.btnLoading = false;
      },
      error: (err:any) => {
        this.toast.error(err.error.message);
        this.btnLoading = false;
      },
      complete: () => {
        this.btnLoading = false;
      }
    });
  }


  handleOpenEditLeadSpaceTab(content: any, spaceId: any, space_name:any): void {

    this.idToEdit = spaceId;
    this.nameLeadSpaceToEdit = space_name;

    this.modalService.open(content, {
      size: 'md',
      scrollable: true,
      centered: true,
    });

    this.currentSpace_id = null;
    console.log(spaceId)
  }

  handleOpenDeleteSpaceModel(content: any, spaceId: number): void {
    this.idToDelete = spaceId;
    this.modalService.open(content, {
      size: 'md',
      scrollable: true,
      centered: true,
    });
    this.currentSpace_id = null;
  }

  handleDeleteLeadSpace(): void {
    this.btnLoading = true;
    
    const payload = {
      client_id: this.localStorage.getClientId(),
      id: this.idToDelete,
    };
    this.api.deleteLeadSpace(payload).subscribe({
      next: (res:any) => {
        this.getLeadSpace();
      this.changeTab('all_leads');
       setTimeout(() => {
        // all_leads_tab click on this tab 
        
        this.toast.success("Lead Space Deleted Successfully");
        this.modalService.dismissAll();
        this.btnLoading = false;

       }, 1500);

      },
      error: (err:any) => {
        this.toast.error("An error occured while deleting the lead space");
        this.btnLoading = false;

      },
    
    });
  }


  closeMenu(): void {
    this.currentSpace_id = null; // Reset to hide the menu
    
  }


  handleOpenMoveLeadsModel(content: any, element:any): void {
    this.leadToMove = element;
    this.modalService.open(content, {
      size: 'md',
      scrollable: true,
      centered: true,
    });
  }

  handleMoveLeads(): void {

    if(this.selectedLeadSpace){
      this.formAction = 'update';
      this.editFieldValues = this.leadToMove;
      this.preUserId = this.leadToMove.user_id;
      this.editFieldValues.lead_space_id = this.selectedLeadSpace;
  
      this.btnLoading = true;
      
      this.SaveLead(true)
    }
    else {
      this.toast.error("Please select lead space name")
    }
  



  }





  checkNull(data:any,col:any){
    
    let row = ""
    try {
      if(data['fields'][col]!=null ){
        row = data['fields'][col]
       // console.log("--->",data['fields'][col])
      }
    }
    catch(err) {
      
    }
   
   
    return row; 
  }
  dateFilter(event: any) {
    this.dateQuery.setValue(event);
  }
  followUps(content: any, element: any) {
    this.modalService.open(content, { size: 'xl', scrollable: true });
    this.getFollowUps(element.lead_id);
  }
  getFollowUps(id: any) {
    this.followupLoading = true;
    this.leadsFollowList = [];
    let param = {
      lead_id: id.toString(),
      client_id: this.localStorage.getClientId(),
    };
    this.api.getLeadsFollowup(param).subscribe({
      next: (res) => {
        this.leadsFollowList = res || [];
        this.followupLoading = false;
      },
      error: (err) => {
        this.followupLoading = false;
      },
    });
  }
  changeTab(tab: any) {
    this.dataSource.data = [];
    this.currentTab = tab;
    this.currentActiveTabId = null;
    this.isDataLoading = true;
    this.loadData();
    this.currentSpace_id =null;
  }

  open(content: any) {
    this.formAction = 'add';
    this.getAllUsers();
    this.clearleadForm();
    this.modalService
      .open(content, { size: 'xl', scrollable: true })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  openList(content: any) {
    if (this.temp.length < 1) {
      this.toast.error('Please select a record to create the list !');
    } else {
      this.formAction = 'add';
      this.modalService
        .open(content, { size: 'xl', scrollable: true })
        .result.then(
          (result) => {
            this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  edit(content: any, object: any) {
    this.editFieldValues = {};
    this.getAllUsers();
    this.preUserId = '';
    this.formAction = 'update';
    this.remarkValue = '';
    this.notification_date = '';
    this.next_action = '';
    this.modalService.open(content, { size: 'xl', scrollable: true });
    this.editFieldValues = object;
    this.preUserId = object.user_id;
  }

  scrollToError(e: any, container: HTMLElement) {
    if (!e.form.valid) {
      Object.keys(e.form.controls).forEach((key) => {
        if (e.form.controls[key].errors != null) {
          const el = document.getElementById(key);
          if (el) {
            const offset = el.offsetTop - 100;
            container.scrollTo({
              top: offset,
              behavior: 'smooth',
            });
            el.focus();
          }
        }
      });
    }
  }

  handleClickOutside(){
    console.log("alert 123")

  }

  // save Lead Data
  SaveLead(isValid: any) {
    console.log(this.currentActiveTabId);
    this.isSubmitted = true;
    if (isValid) {
      if (this.formAction == 'add') {
        this.isSubmitted = true;
        this.btnLoading = true;
        this.createFieldFormValue.client_id = this.localStorage.getClientId();
        this.createFieldFormValue.center_id = this.localStorage.getCenterId();
        this.createFieldFormValue.fields = this.fieldValues;
        this.createFieldFormValue.lead_date = moment().format('YYYY-MM-DD');
        this.createFieldFormValue.lead_time = moment().format('HH:mm');
        this.createFieldFormValue.lead_space_id = this.currentActiveTabId || null;


        if(this.isCenter){
          this.createFieldFormValue.center_id = this.center_id;
        }
        if (this.createFieldFormValue.user_id) {
          this.users.filter((user: any) => {
            if (user.user_id == this.createFieldFormValue.user_id) {
              this.createFieldFormValue.email = user.email;
              this.createFieldFormValue.username =
                user.prefix + ' ' + user.fname + ' ' + user.lname;
            }
          });
        }

        if (!this.createFieldFormValue.user_id) {
          this.createFieldFormValue.user_id = this.localStorage.getUserId();
          this.createFieldFormValue.email = this.localStorage.getEmail();
          this.createFieldFormValue.username =
            this.localStorage.getProfileName();
        }

        this.api.addLeadData(this.createFieldFormValue).subscribe({
          next: (c) => {
            this.modalService.dismissAll();
            if(!this.currentActiveTabId || this.currentActiveTabId == 'all_leads' || this.currentActiveTabId == null || this.currentActiveTabId == undefined){
            this.loadData();
            }else{
              this.loadDataOnTabs(this.currentActiveTabId)
            }
            this.btnLoading = false;
            this.toast.success('Lead Added Successfully', 'Success!!');
            this.tracker.track("Add","Leads","User Added:New Lead ",c.lead_id)
            this.resetFormValues();
          },
          error: (e) => {
            this.btnLoading = false;
          },
          complete: () => {},
        });
      } else if (this.formAction == 'update') {
        this.btnLoading = true;
        this.editFieldValues.center_id = this.localStorage.getCenterId();
        if (this.editFieldValues.user_id) {
          if (this.preUserId != this.editFieldValues.user_id) {
            this.users.filter((user: any) => {
              if (user.user_id == this.editFieldValues.user_id) {
                this.editFieldValues.email = user.email;
                this.editFieldValues.username =
                  user.prefix + ' ' + user.fname + ' ' + user.lname;
              }
            });
          }
        }

        this.api.updateLead(this.editFieldValues).subscribe({
          next: (c) => {
            this.modalService.dismissAll();
          
            this.isSubmitted = false;
            this.btnLoading = false;
            this.toast.success('Lead Updated Successfully', 'Success!!');
            this.tracker.track("Update","Leads","User Update:Existing Lead Details",this.editFieldValues.lead_id)
            this.lead = new Lead();
            this.selectedLeadSpace = null;
            this.loadData();
           
          },
          error: (e) => {
            this.btnLoading = false;
            this.isSubmitted = false;
          },
          complete: () => {},
        });
      }
      // console.log(this.lead)
    }
  }
  fnCheckNotificationDate(event:any){
   this.isReminderChecked = event.target.checked;
   if(this.isReminderChecked==false){
    this.notification_date = ""
   }
  }
  updateLeadsRemarks()
  {
    //alert(this.isCron)
    if(((this.isReminderChecked == true && this.notification_date == "") && this.isCron == 'True')){
      this.toast.error("Please add notification reminder date")
    }
    else 
    if(this.next_action=="")
      {
        this.toast.error("Please add next action")
      }
    else if(this.remarkValue=="")
    {
      this.toast.error("Please add text in update detail")
    }
    else
    {
      this.btnUpdateLoading = true;
      const user_id = parseInt(this.localStorage.getUserId());
      this.leadFolloup = {
        client_id: this.localStorage.getClientId(),
        lead_id: this.editFieldValues.lead_id,
        date: moment().format('YYYY-MM-DD'),
        time: moment().format('HH:mm'),
        remarks: this.remarkValue,
        notification_date:
          this.notification_date == '' ? null : this.notification_date,
        user_id: user_id,
        next_action: this.next_action,
      };
      this.api.addLeadFollowUp(this.leadFolloup).subscribe({
        next: (c) => {
          this.btnUpdateLoading = false;
          this.isSubmitted = false;
          this.toast.success("Update Added successfully")
          this.tracker.track("Followup","Leads","User Followup:Lead Followup Added Successfully",this.editFieldValues.lead_id)
          this.loadData();
          this.remarkValue = ""
          this.notification_date = ""
          this.next_action = ""
          //this.modalService.dismissAll();
        },
        error: (e) => {
          this.btnLoading = false;
          this.isSubmitted = false;
        },
        complete: () => {},
      });
    }
    
  }
  deleteLead() {
    this.btnLoading = true;
    this.api.deleteLeadData(this.deleteParam).subscribe({
      next: (data: any) => {
        this.modalService.dismissAll();
        this.btnLoading = false;
        this.toast.success("Lead Deleted Successfully");
        this.loadData();
      },
      error: (error: any) => {
        this.btnLoading = false;
        this.toast.error('An error occured');
      },
    });
    this.tracker.track("Delete","Leads","User Action:Lead Deleted",this.deleteParam.lead_id)
  }
  deleteFieldModal(content: any, id: any) {
    let param = {
      lead_id: id,
      client_id: this.localStorage.getClientId(),
    };
    this.deleteParam = param;
    this.modalService.open(content, {
      size: 'l',
      scrollable: true,
      centered: true,
    });
  }
  resetFormValues() {
    this.createFieldFormValue = {
      fields: {},
      lead_date: '',
      lead_time: '',
    };
    this.fieldValues = {};
    this.allLeadFeilds.forEach((element: any) => {
      if (element.sub_fields && element.sub_fields.length > 0) {
        this.fieldValues[element.field_name + '|subf'] = [];
      }
      let inc = 0;
      if (element.sub_fields && element.sub_fields.length > 0) {
        element.sub_fields.forEach((sub: any) => {
          this.fieldValues[element.field_name + '|subf'].push({
            [sub.field_name + '|' + inc]: '',
          });
          inc++;
        });
      }
    });
    this.isSubmitted = false;
  }

  SaveLeadFollowUp(isValid: any) {
    this.isSubmitted = true;
    if (isValid) {
      this.btnLoading = true;
      this.leadFolloup.lead_id = this.lead.lead_id;

      this.lead.hospital_id = this.localStorage.getHospitalId();
      this.api.addLeadFollowUp(this.leadFolloup).subscribe({
        next: (c) => {
          this.btnLoading = false;
          this.isSubmitted = false;
          this.toast.success('Lead Followup Added Successfully', 'Success!!');
        },
        error: (e) => {
          this.btnLoading = false;
          this.isSubmitted = false;
        },
        complete: () => {},
      });
    }
  }
  importData(event:any){
    if(event.length > 0){
       this.uploadBtnLoading = true
       this.api.createBulkLead(event).subscribe({
        next:(res)=>{
          if(res) {
            this.uploadBtnLoading = false
            this.toast.success("Data uploaded Successfully")
            this.modalService.dismissAll()
            this.loadData()
          }
        },
        error:(e)=>{
          this.uploadBtnLoading = false
        }
       })
    }
    else {

      this.toast.error("Please Upload Data")
    }
    console.log(event)
  }
  exportSampleFile(event:any){
    let col = ['Contact Name',"Country Code","Contact Number","Alternate Contact","Email","Alternate Email",'Organisation',"Designation","Sector","Sub Sector","Country","State","City","Lead Type","Lead Status","Source","Additional information","Deal Size","Website Link","Linked Link","Date"];
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    console.log(col)
    XLSX.utils.sheet_add_aoa(ws, [col]);
    XLSX.utils.book_append_sheet(wb, ws, 'Sample Lead');
    
    XLSX.writeFile(
      wb,
      'sample_data.xlsx'
    );
  }
  export(header: any) {
    
    this.exportArrayData = [];
    let param = {
      where: { client_id: this.localStorage.getClientId() },
    };
    this.isExportLoading = true;

    this.api.getLeadsRocord(param).subscribe({
      next: (data) => {
        this.isExportLoading = false;
        this.leadsRecord = data || [];
        this.isLoading = false;
        if (data.length > 0) {
          data.forEach((elem: any) => {
            console.log(elem);
            const obj = {
              ...elem.fields,
              ...elem,
              assign_to:
                elem.user == null
                  ? ''
                  : elem.user.prefix +
                    ' ' +
                    elem.user.fname +
                    ' ' +
                    elem.user.lname,
            };
            this.exportArrayData.push(obj);
          });
        }

        let excelRowData: any = [];
        this.exportArrayData.forEach((element: any) => {
          let obj: any = {};
          let index = 0;
          this.exportColumns.forEach((col: any) => {
            obj[index] = element[this.exportDataMap[col]];
            index++;
          });
          excelRowData.push(obj);
        });
        let excelData = [];
        let excelHeader = [];
        for (let i = 0; i < excelRowData.length; i++) {
          let element = excelRowData[i];
          let obj: any = {};
          for (let index = 0; index < header.length; index++) {
            let key = header[index]['key'];
            obj[key] = element[key];
          }
          excelData.push(obj);
        }
        for (let index = 0; index < header.length; index++) {
          let value = header[index]['value'];
          excelHeader.push(value);
        }
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(ws, [excelHeader]);
        XLSX.utils.sheet_add_json(ws, excelData, {
          origin: 'A2',
          skipHeader: true,
        });
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        let date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        header = [];
        XLSX.writeFile(
          wb,
          'Leads_data_' +
            day +
            '_' +
            month +
            '_' +
            year +
            '_' +
            date.getTime() +
            '.csv'
        );
      },
      error: (e) => {
        this.isExportLoading = false;
        this.isLoading = false;
      },
    });
  }

  addSubscriberList(isValid: any) {
    this.isSubmitted = true;
    if (isValid) {
      this.btnLoading = true;
      let list_number: any = [];
      let list_email: any = [];
      console.log(this.temp);
      const list = this.temp.reduce((res: any, item: any) => {
        if (item.mobile) {
          list_number.push(item.mobile);
        }
        if (item.email) {
          list_email.push(item.email);
        }
        res.push({
          lead_id: item.lead_id,
          client_id: item.client_id,
          fname: item.fname,
          lname: item.lname,
          name: item.name,
          mobile: item.mobile,
          email: item.email,
        });
        return res;
      }, []);
      let params = {
        subscriber_name: this.subsciberListName,
        client_id: this.localStorage.getClientId(),
        total_subscriber: this.temp.length,
        subscriber_list: list,
        subscriber_phones: list_number,
        subscriber_emails: list_email,
      };
      this.api.addSubcriberList(params).subscribe({
        next: (c) => {
          this.modalService.dismissAll();
          this.temp = [];
          this.loadData();
          this.btnLoading = false;
          this.isSubmitted = false;
          this.toast.success('Subscriber List Added Successfully', 'Success!!');
        },
        error: (e) => {
          this.btnLoading = false;
          this.isSubmitted = false;
        },
        complete: () => {},
      });
    }
  }

  isAllSelected() {
    return this.dataSource.data.every((item) => item.isSelected);
  }

  isAnySelected() {
    return this.dataSource.data.some((item) => item.isSelected);
  }

  hell(event: any, element: any) {
    if (element.isSelected == true) {
      this.temp.push(element);
    }
    if (element.isSelected == false) {
      console.log('unchecked---');
      this.temp = this.temp.filter(function (obj: any) {
        return obj.lead_id !== element.lead_id;
      });
    }
    this.users = this.temp;
    this.mergedArr = this.temp;
    console.log('heloo3 ------------', this.temp);
  }

  selectAll(event: any) {
    if (event.checked == false) {
      console.log('false ------------');
      this.temp = [];
    }
    this.dataSource.data = this.dataSource.data.map((item) => ({
      ...item,
      isSelected: event.checked,
    }));
    if (event.checked == true) {
      console.log('true ------------');
      this.temp = this.dataSource.data;
    }
    console.log('-- ', this.temp);
  }
}
function observableOf(arg0: null): any {
  console.log(arg0);
  throw new Error('Function not implemented.');
}

export class Lead {
  isSelected = 'isSelected';
  lead_id!: Number;
  fname: string = '';
  lname: string = '';
  leadsfollowup!: JSON;
  prefix: string = '';
  date_of_lead: String = moment().format('YYYY-MM-DD');
  time_of_lead: String = moment().format('HH:mm');
  gender: string = '';
  age: Number = 0;
  email: string = '';
  mobile: string = '';
  state: string = '';
  country: string = '';
  city: string = '';
  address: string = '';
  query: string = '';
  leadStattus: string = '';
  client_id: string = '';
  hospital_id: Number = 0;
  leadChannel: String = '';
}
export class LeadFollowup {
  client_id: string = '';
  lead_id!: Number;
  user_id!: Number;
  date: string = '';
  time: string = '';
  remarks: string = '';
  notification_date = null;
  next_action: string = '';
}
