import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { DateFilterComponent } from '../../ui/date-filter/date-filter.component';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { UsersService } from 'src/app/private/services/users.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  
  DateFilterComponent = DateFilterComponent;
  showFilter:boolean = false;
  activeFilter:string = 'gender';
  FilterdateStart:Date =  new Date();
  Filterdateend:Date =  new Date();
  isActive: string = 'Add New';
  isGroupActive: string = '';
  filterType:any = "";
  SearchInput:any;
  errorMsg:any

  @Input() topBarTitle !: string;
  @Output("create") Create: EventEmitter<any> = new EventEmitter();
  @Output("ExportData") ExportData: EventEmitter<any> = new EventEmitter();
  @Output("ResetDateFilter") ResetDateFilter: EventEmitter<any> = new EventEmitter();
  @Output("ResetFilter") ResetFilter: EventEmitter<any> = new EventEmitter();
  @Output("DateFilter") DateFilter: EventEmitter<any> = new EventEmitter();
  @Output("searchFilter") searchFilter: EventEmitter<any> = new EventEmitter();
  @Output("openList") openList: EventEmitter<any> = new EventEmitter();
  @Output("downLoadSample") downLoadSample: EventEmitter<any> = new EventEmitter();
  @Output("sendImportData") sendImportData: EventEmitter<any> = new EventEmitter();
  @Input() title = "";
  @Input() isDatefilter = false;
  @Input() isExport = false;
  @Input() isAdd = false;
  @Input() filterArray:any = [];
  @Input() importStatus:boolean = false;
  
  @Input() exportArray:any = [];
  @Input() isExportLoading = false;
  @Input() isImport = false;
  @Input() filterData = [];
  check_true:boolean = false;
  filterCol:any = [];
  users:any = [];
  importDataArray:any = [];
  actionButton:any = "search"
  toggleExport:boolean = false;
  toggleDropDown:boolean = false;
  toggleDropDownType:any = "";
  constructor(private modalService :NgbModal  ,private toast:ToastrService,private localStorage:LocalStorageService,private userApi:UsersService ) { }
  ngOnInit(): void {
    this.getAllUsers();
    console.log(this.filterData)
  }
  openImport(content:any):void {
    this.modalService.open(content, { size: 'md', scrollable: false });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['isExportLoading']) {
      if(this.isExportLoading==true){
        //alert(`Received Data: ${this.isExportLoading}`);
        this.filterCol = []
      }
   
    
    }
  }

  clearFilterColumn() {
    this.filterCol = [];
  }

  downloadSampleExcel(){
    this.downLoadSample.emit()
  }


  toggleExportFn()
  {
    this.toggleExport = (this.toggleExport) ? false :  true

    if(this.toggleExport)
    {
      this.isActive = 'Export'
    }
  }
  getAllUsers() {
    let param = {
      client_id: this.localStorage.getClientId(),
    };
    this.userApi.findAllUsers(param).subscribe({
      next: (res: any) => {
        this.users = res || [];
      },
      error: (e: any) => {
        console.error(e);
      },
    });
  }
  addFilterCol(event:any,i:any)
  {
       if(event.target.checked)
       {
        this.filterCol.push({key:i,value:event.target.value})
       }
       else
       {
        this.filterCol =  this.filterCol.filter(((item:any)=> item.key !=i))
       }
  }
  fnSelectAll(event:any)
  {
    var checkboxes:any = document.getElementsByClassName('filter_check');
       if(event.target.checked)
       {

        for (var i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].type == 'checkbox') {
            console.log(checkboxes.length)
              checkboxes[i].checked = true;
              this.filterCol.push({key:i,value:checkboxes[i].value})
          }
         }
       }
       else
       {
        for (var i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].type == 'checkbox') {
              checkboxes[i].checked = false;
          }
      }
        this.filterCol = [];
       }
  }
  
  TypeToFilter(event:any){
    // alert(event.target.value)
     let object:any = {}
     object['lead_type'] = event.target.value;
     this.searchFilter.emit(object);
   }
   LeadStatusFilter(event:any) {
    let object:any = {}
    object['lead_status'] = event.target.value;
    this.searchFilter.emit(object);
   }
   searchByFilterQuery(event:any,type:any){
   // alert(event.target.value)
    let object:any = {}
    object[type] = event.target.value;
    this.searchFilter.emit(object);
  }
  changeFilter(event:any)
  {
    this.isActive= 'Filter'
    this.toggleExport = false;
    //alert(event.target.value)
    if(event.target.value=="assign_to") {
      this.toggleDropDownType = event.target.value;
      this.actionButton = "reset"
    }
    else if(event.target.value=="lead_type") {
      this.toggleDropDownType = event.target.value;
      this.actionButton = "reset"
    }
    else if(event.target.value=="lead_status") {
      this.toggleDropDownType = event.target.value;
      this.actionButton = "reset"
    }
    else if(event.target.value=="region") {
      this.toggleDropDownType = event.target.value;
      this.actionButton = "reset"
    }
    else if(event.target.value=="segment") {
      this.toggleDropDownType = event.target.value;
      this.actionButton = "reset"
    }
    else if(event.target.value=="enquiry") {
      this.toggleDropDownType = event.target.value;
      this.actionButton = "reset"
    }
    else if(event.target.value=="product") {
      this.toggleDropDownType = event.target.value;
      this.actionButton = "reset"
    }
    else if(event.target.value=="currency") {
      this.toggleDropDownType = event.target.value;
      this.actionButton = "reset"
    }
    else if(event.target.value=="po quarter") {
      this.toggleDropDownType = event.target.value;
      this.actionButton = "reset"
    }
    else if(event.target.value=="po - fy") {
      this.toggleDropDownType = event.target.value;
      this.actionButton = "reset"
    }
    
    else {
      this.toggleDropDownType = '';
      this.toggleDropDown = false;
        this.actionButton = "search"
    }
  }
  resetFilter()
  {
    this.actionButton = 'search'; 
    this.SearchInput = ""
    this.toggleDropDown = false;
    this.toggleDropDownType = "";
    
    this.filterType = ""
    this.searchFilter.emit("")
    this.ResetFilter.emit("reset")
  }

  createNewGroup(){
    this.openList.emit();
    this.toggleExport = false;
  }

  createMethod()
  {
    this.Create.emit();
    this.isActive= 'Add New'
    this.toggleExport = false;
  }

  getFilterValue ()
  {
    let type = this.filterType;
    let query = this.SearchInput
    let object:any = {}

    if(type=="")
    {
      this.toast.error("Select Filter Type")
      this.errorMsg = "Select Filter Type"
      setTimeout(() => {
        this.errorMsg = ""
      }, 1000);
   
    }
  

    else
    {
      if(query=="" || query == undefined)
    {
      this.toast.error("Enter query")
      this.errorMsg = "Enter query"
      setTimeout(() => {
        this.errorMsg = ""
      }, 1000);
      this.searchFilter.emit('');
   
    }
    else
    {
      object[type] = query;
      this.searchFilter.emit(object);
      this.actionButton = 'reset'; 
    }

    }
 
  }
  exportExcel()
  {
    if(this.filterCol.length == 0)
    {
      this.toast.error("Please select column")
      return;
    }
    this.ExportData.emit(this.filterCol);
    this.toggleExport = false;
   
  }
  applyFilter(event: any) {
    let filterValue = event.target.value;
}
filterByDateRange()
{
  let start = (this.FilterdateStart!=null) ? new Date(this.FilterdateStart.setHours(0,0,0,0)) :this.FilterdateStart;;
  let end =  (this.Filterdateend!=null) ? new Date(this.Filterdateend.setHours(0,0,0,0)) :this.Filterdateend;

  let startd = moment(start).format("YYYY-MM-DD")
  let endb = moment(end).format("YYYY-MM-DD")
  if(startd!= "Invalid date" && endb!= "Invalid date")
  {
    let object = {startDate:startd,endDate:endb}
    this.DateFilter.emit(object)
  }

}
getImportedData(event:any) {
  this.importDataArray = event;
  
}
submitImport() {
  this.sendImportData.emit(this.importDataArray)
}
resetDatePicker()
{
  this.FilterdateStart=  new Date();
  this.Filterdateend=  new Date();
  this.DateFilter.emit("")
  this.ResetDateFilter.emit("reset")
}




}
