import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './public/login/login.component';
import { MainComponent } from './private/layout/main/main.component';
import { HomeComponent } from './private/modules/home/home.component';
import { ForgetComponent } from './public/forget/forget.component';
import { ResetComponent } from './public/reset/reset.component';
import { LeadComponent } from './private/modules/lead/lead.component';
import { UsersComponent } from './private/modules/configuration/users/users.component';
import { AuthGuard } from './utils/auth.guard';
import { ScheduleComponent } from './private/modules/configuration/schedule/schedule.component';
import { EmailSettingsComponent } from './private/modules/configuration/email-settings/email-settings.component';
import { CampaignManagementComponent } from './private/modules/components/campaign-management/campaign-management.component';
import { LeadsComponent } from './private/modules/configuration/leads/leads.component';
import { RegistrationComponent } from './private/modules/configuration/registration/registration.component';
import { LeadFormComponent } from './public/lead-form/lead-form.component';
import { NotFoundComponent } from './public/not-found/not-found.component';
import { WhatsappComponent } from './private/modules/configuration/whatsapp/whatsapp.component';
import { ContactsComponent } from './private/modules/contacts/contacts.component';
import { UserLogsComponent } from './private/modules/user-logs/user-logs.component';
import { EventsComponent } from './private/modules/events/events.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: "full",
    title: "TINA"
  },

  {
    path: 'login',
    component: LoginComponent,
    title: "TINA"
  },
  {
    path: 'forget-password',
    component: ForgetComponent,
    title: "Forget Password"
  },
  {
    path: 'reset-password/:type/:token',
    component: ResetComponent,
    title: "Reset Password"
  },
  {
    path: 'capture-leads/:client/:center',
    component: LeadFormComponent,
    title: "TINA"
  },
  {
    path: 'main',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [{
      path: "",
      data: {
        title: "Manage Leads"
      },
      component: LeadComponent,
      canActivate: [AuthGuard],
    }, {
      path: 'home',
      component: HomeComponent,
      title: "Home",
      data: {
        title: "Analytics Dashboard"
      },
      canActivate: [AuthGuard]
    }, {
      path: 'leads',
      component: LeadComponent,
      canActivate: [AuthGuard],
      title: "Manage Leads",
      data: {
        title: "Manage Leads"
      }
    },
    // events
    {
      path: 'events',
      component:EventsComponent,
      canActivate: [AuthGuard],
      title: "Events",
      data: {
        title: "Manage Events"
      }
    },
    {
      path: 'whatsapp-campaigns',
      component: WhatsappComponent,
      canActivate: [AuthGuard],
      title: "WhatsApp Campaigns",
      data: {
        title: "WhatsApp Campaigns"
      }
    },
    {
      path: 'contacts',
      component: ContactsComponent,
      canActivate: [AuthGuard],
      title: "Contacts",
      data: {
        title: "Contacts"
      }
    },
    {
      path: 'configuration-users',
      component: UsersComponent,
      title: "Users",
      canActivate: [AuthGuard],
      data: {
        title: "Manage Users"
      }
    },
    {
      path: 'campaign',
      component: CampaignManagementComponent,
      title: "Campaign",
      data: {
        title: "Campaign Management"
      }
    },
    {
      path: 'user-logs',
      component: UserLogsComponent,
      title: "User Logs",
      data: {
        title: "User Logs"
      }
    },
    {
      path: 'configuration-email',
      component: EmailSettingsComponent,
      canActivate: [AuthGuard],
      title: "Email",
      data: {
        title: "Email Settings"
      }
    },
    {
      path: 'configuration-schedule',
      component: ScheduleComponent,
      canActivate: [AuthGuard],
      title: "Schedule",
      data: {
        title: "Schedule Settings"
      }
    },
    {
      path: 'configuration-leads',
      component: LeadsComponent,
      canActivate: [AuthGuard],
      title: "Leads",
      data: {
        title: "Leads Settings"
      },
    },
    {
      path: 'configuration-registration',
      component: RegistrationComponent,
      canActivate: [AuthGuard],
      title: "Registrations",
      data: {
        title: "Registrations Settings"
      },
    },
    {
      path: 'appointments',
      loadChildren: () => import('./private/modules/appointment/appointment.module').then(m => m.AppointmentModule),
      canActivate: [AuthGuard],
      title: "Appointments",
      data: {
        title: "Manage Appointments"
      }
    },
    ]

  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
