import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, Injector, NgZone } from "@angular/core";
import { LoggingService } from "./logginService";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private injector: Injector,
    private zone: NgZone
  ) {}
  handleError(error: any): void {
    const loggingService = this.injector.get(LoggingService);
    try {
      if (error instanceof HttpErrorResponse) {
        // Server error
        loggingService.logError('Server error', error.message);
      } else {
        // Client Error
        loggingService.logError('Client error', error.message);
      }

      console.error('Global Error Handler:', error);
    } catch (handlerError) {
      console.error('Error in GlobalErrorHandlerService:', handlerError);
    }
  
  }
  // handleError(error: any) {
  //   this.zone.run(() =>
  //   {
  //   console.error(error || 'Undefined client error')
  //    } );
  // }
}