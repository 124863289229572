import { Component, OnInit } from '@angular/core';
import { JWTTokenServiceService } from 'src/app/public/services/token.service';
import { HomeService } from '../../services/home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent  {
  active = 1;
  data:any ;
  constructor(private jwt:JWTTokenServiceService, private api:HomeService)
  {
   // console.log(jwt.isTokenExpired())
    //console.log(jwt.getDecodeToken())
  } 

  ngOnInit(): void {
    this.fnLoadAnalyticsData();
  }
  
  fnLoadAnalyticsData()
  {
    let param = {
      client_id: "b0808768-9544-4e5d-820c-9f1f35402e9c",
  }

    this.api.getAnalyticsData(param).subscribe({
      next:(res)=>{
        console.log(res)
        this.data = res;
      }, 
      error:(e)=>{},
      complete:()=>{}
    })
  }
}
