import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(private http: HttpClient) { }

  getAllEvents(data:any): Observable<any> {
    return this.http.post(environment.getAllEvents,data);
  }

  createEvent(data:any): Observable<any> {
    return this.http.post(environment.createEvent,data);
  }

  updateEvent(data:any): Observable<any> {
    return this.http.post(environment.updateEvent,data);
  }

  deleteEvent(data:any): Observable<any> {
    return this.http.post(environment.deleteEvent,data);
  }
}
