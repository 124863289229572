import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forget',
  templateUrl: './forget.component.html',
  styleUrls: ['./forget.component.scss']
})
export class ForgetComponent {
  submitted = false;
  isLoading:boolean = false;
  buttonTitle = "Submit"
  form: FormGroup = new FormGroup({
    email: new FormControl(''),
  });
  url:any
  constructor(private toast:ToastrService, private coreAuth:AuthService, private router:Router, private formBuilder: FormBuilder)
  {
    this.url = window.location.href.replace("forget-password","")

  }
  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        email: ['',[Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]]
      }
    );
  }
  get formError(): any {
    return this.form.controls;
  }
  onSubmit()
  {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
   this.isLoading = true;
   this.buttonTitle = "Loading"
   let param = {...this.form.value,...{resetUrl: this.url+"reset-password"}}
   this.coreAuth.forgetPassword(param).subscribe({
    next: (res) => {

      this.buttonTitle = "Resend"
      this.isLoading = false;
      this.toast.success(res.message,'Email Sent');
    },
    error: (e) => {
      this.buttonTitle = "Resend"
      this.isLoading = false;
    },
    complete: () => {
      this.buttonTitle = "Resend"
      this.isLoading = false;
    }
})

  }
  login()
  {
    this.router.navigate(['login']);
  }
}
