import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { catchError, map, merge, startWith, switchMap } from 'rxjs';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { LeadService } from '../../services/lead.service';
import { MatTableDataSource } from '@angular/material/table';
import { UsersService } from '../../services/users.service';
import moment from 'moment';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { EventService } from '../../services/event.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})


export class EventsComponent {
  searchQuery = new FormControl();
  sort!: MatSort;
  paginator!: MatPaginator;
  dateQuery = new FormControl();
  center_id: any;
  totalRows = 0;
  temp: any = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  formAction = 'add';
  users: any = [];
  lead: Lead = new Lead();
  closeResult = '';
  accessObject: any = {}
  filterArray: any = [];
  current_id_hover: any;
  currentPage = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 100];
  initialLoad = true;
  updateData: any;
  deleteEventId: any;
  deleteEventName: any;
  btnLoading:boolean = false;


  displayedColumns: string[] = [
    "event_id",
    "event_name",
    "location",
    "status",
    "event_url",
    "start_date",
    "end_date",
    "action"
  ];











  constructor(
    private localStorage: LocalStorageService,
    private api: EventService,
    private userApi: UsersService,
    private modalService: NgbModal,
    private toast: ToastrService,

  ) { }

  ngAfterViewInit() {
    console.log("tabl column : ", this.displayedColumns);
    this.loadData();
  }




  searchFilter(query: any) {
    this.searchQuery.setValue(query);
  }

  // load Initial table data from based on source type
  loadData() {

    try {
      // this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      merge(
        this.dateQuery.valueChanges,
        this.searchQuery.valueChanges,
        // this.sort.sortChange,
        // this.paginator.page
      )
        .pipe(
          startWith({}),
          switchMap(() => {
            console.log("client_id", this.localStorage.getClientId())
            let param = {
              "client_id": this.localStorage.getClientId(),
            }

            return this.api
              .getAllEvents(param)
              .pipe(catchError(() => observableOf(null)));
          }),
          map((response: any) => {
            this.initialLoad = false;
            if (response === null) {
              return [];
            }
            this.totalRows = response.length;
            console.log("response", response.length)
            return response;
          })
        )
        .subscribe({
          next: (data) => {
            this.dataSource = new MatTableDataSource<any>(data);
          },
          error: (e) => {
            this.initialLoad = false;
            console.error(e);
          },
          complete: () => { 
            this.initialLoad = false;
          },
        });
    } catch (e) {
      this.initialLoad = false;
      console.error(e);
    }
  }

  open(content: any) {
    this.formAction = 'add';
    this.getAllUsers();
    this.clearleadForm();
    this.modalService
      .open(content, { size: 'xl', scrollable: true })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }



  getAllUsers() {
    let param = {
      client_id: this.localStorage.getClientId(),
    };
    this.userApi.findAllUsers(param).subscribe({
      next: (res: any) => {
        this.users = res || [];
      },
      error: (e: any) => {
        console.error(e);
      },
    });
  }

  clearleadForm() {
    this.lead = new Lead();
  }

  openList(content: any) {
    if (this.temp.length < 1) {
      this.toast.error('Please select a record to create the list !');
    } else {
      this.formAction = 'add';
      this.modalService
        .open(content, { size: 'xl', scrollable: true })
        .result.then(
          (result) => {
            this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }
  }



  dateFilter(event: any) {
    this.dateQuery.setValue(event);
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  formatDate(time: string): string {
    const date = new Date(time);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',  // "long" for full month name (e.g., "August"), "short" for abbreviated (e.g., "Aug")
      day: 'numeric'  // "numeric" for day of the month as a number
    };
    return date.toLocaleDateString('en-US', options);

  }

  openEdit(content: any, event: any) {
    console.log("event", event);
    this.formAction = 'Update';
    this.updateData = event;
    this.modalService
      .open(content, { size: 'xl', scrollable: true })
  }

  openDelete(content: any, event: any) {
    this.deleteEventId = event.event_id;
    this.deleteEventName = event.event_name;
    this.modalService
      .open(content, { size: 'md', scrollable: true, centered: true })
  }

  handleDeleteEvent():void {
    let param = {
      "event_id": this.deleteEventId,
    }
    this.btnLoading = true;
    this.api.deleteEvent(param).subscribe({
      next: (res: any) => {
        this.loadData();
        this.toast.success('Event Deleted Successfully');
        
        this.btnLoading = false;
      },
      error: (e: any) => {
        console.error(e);
        this.btnLoading = false;
        this.toast.error('Failed to delete event');
      },
      complete: () => {
        // close model
        this.modalService.dismissAll();
      }
    });


  }





}

function observableOf(arg0: null): any {
  console.log(arg0);
  throw new Error('Function not implemented.');
}

export class Lead {
  isSelected = 'isSelected';
  lead_id!: Number;
  fname: string = '';
  lname: string = '';
  leadsfollowup!: JSON;
  prefix: string = '';
  date_of_lead: String = moment().format('YYYY-MM-DD');
  time_of_lead: String = moment().format('HH:mm');
  gender: string = '';
  age: Number = 0;
  email: string = '';
  mobile: string = '';
  state: string = '';
  country: string = '';
  city: string = '';
  address: string = '';
  query: string = '';
  leadStattus: string = '';
  client_id: string = '';
  hospital_id: Number = 0;
  leadChannel: String = '';
}
