import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { EmailService } from 'src/app/private/services/email.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-email-settings',
  templateUrl: './email-settings.component.html',
  styleUrls: ['./email-settings.component.scss']
})
export class EmailSettingsComponent {
  active = "gmail"
 email:EmailSetting = new EmailSetting();
 @ViewChild("priceSettingsForm")
 EmailForm!: NgForm;
 isSubmitted:boolean = false;
 constructor(private toast:ToastrService,private api:EmailService,private localStorage:LocalStorageService)
 {
 
 }
 ngOnInit()
 {
  let param = {
    client_id:this.localStorage.getClientId()
  }
  this.api.getEmailConfig(param).subscribe(
  {
   next:(res)=>{
    if(res)
    {
      this.email = res;
      this.email.password = ""
      console.log(this.email)
    }

   }
  }
  )
 }

 save(isValid:boolean)
 {
  this.isSubmitted = true;
  if(isValid)
  {
    // this.email.center_id = this.localStorage.getCenterId();
    this.email.client_id = this.localStorage.getClientId();
    console.log(this.email)
    if(this.email.id)
    {
      this.api.updateEmailConfig(this.email).subscribe({
        next:(res)=>{
          if(res)
          {
            this.toast.success("Setting Saved...")
          }
        },
        error:(e)=>
        {
  
        }
      })
    }
    else
    {
      this.api.createEmailConfig(this.email).subscribe({
        next:(res)=>{
          if(res)
          {
            this.toast.success("Setting Saved...")
          }
        },
        error:(e)=>
        {
  
        }
      })
    }

 
  }
 }

}

export class EmailSetting {
  id!:Number;
  // center_id:string = "";
  client_id:string = "";
  clientCompliments:string = "";
  clientName:string = "";
  password!:string;
  fromEmail:string = "";
  port:string = "";
  host:string = "";
  clientEmailId:string = "";
  provider:string = "";
}