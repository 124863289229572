import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { LeadService } from 'src/app/private/services/lead.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent {
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  registrationsFeildId: any = null;
  pageSizeOptions: number[] = [10, 25, 100];
  registrationFeild: Registration = new Registration();
  formAction = 'add';
  btnLoading: boolean = false;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  displayedColumns: string[] = [];
  showFullPath: any = {
    path: 'Configuration',
    subPath: 'Registration',
  };
  editRegistrationValue: any =  new Registration();

  constructor(
    private toast: ToastrService,
    private api: LeadService,
    private modalService: NgbModal,
  ) {
    this.getRegistrationFields();
  }

  ngOnInit(): void {}

  getRegistrationFields() {
    // id = this.localStorage.getClientId()
    // test mode on
    let param = {
      client_id: '1',
    };
    this.api.getLeadsFields(param).subscribe((data: any) => {
      this.dataSource = new MatTableDataSource<any>(data.fields);
      this.registrationFeild = data.id;
      this.displayedColumns = [
        'fname',
        'ftype',
        'label',
        'minlen',
        'maxlen',
        'placeholder',
        'required',
        'action',
      ];
    });
  }

  searchFilter(query: any) {}

  editForm(content: any,data: any) {
    this.editRegistrationValue = data;
    this.modalService.open(content, {
      size: 'l',
      scrollable: true,
      centered: true,
    });
  }

  resetValue() {
    this.editRegistrationValue = new Registration();
  }

  open(content: any) {
    this.formAction = 'add';
    this.modalService.open(content, { 
      size: 'l',
      scrollable: true,
      centered: true,
    });
  }

  SaveRegistration() {}

  deleteRegistration(element:any) {}

  updateRegistration() {}

  addOption(opt: any, form: any) {}

  removeOption(index: any, form: any) {}
}

export class Registration {
  "field_id": number = 0;
  "field_name": string = '';
  "label": string = '';
  "type": string = '';
  'max-length': number = 25;
  'min-length': number = 2;
  "placeholder": string = '';
  "required": boolean = true;
  "sequence": number = 0;
  "data": string[] = [];
  "options": string = '';
}