// src/app/services/logging.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor() { }

  logError(errorType: string, errorMessage: string) {
    // Implement logging logic here, e.g., sending logs to a server
    console.log(`${errorType}: ${errorMessage}`);
  }
}
