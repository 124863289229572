import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AppointmentService } from 'src/app/private/services/appointment.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-appointment-detail',
  templateUrl: './appointment-detail.component.html',
  styleUrls: ['./appointment-detail.component.scss']
})
export class AppointmentDetailComponent {

  @Input() patient_id:any;
  @Input() app_id:any;
  @ViewChild("appointmentForm")
  appointmentForm!: NgForm;

  @ViewChild("reappointmentForm")
  reappointmentForm!: NgForm;
  departmentListDp:any = []; 
  isSubmitted:boolean = false;
  btnLoading:boolean =false;
  currentTab:any = {};
  isSubmittedresCheduled:boolean = false;
  DoctorDpList:any = [];
  DateList:any = [];
  TimeList:any = [];
  count:any = 0;
  appointments:Appointment[] = [];
  appointmentTypeList:any = [];
  appointment:Appointment =  new Appointment();
  current_app_id:any;
  current_patient_id:any
  loading:boolean =false;
  constructor(public route:ActivatedRoute,private modalService: NgbModal, private toast:ToastrService,private localStorage:LocalStorageService, private offcanvasService: NgbOffcanvas,private api:AppointmentService , private localstorage:LocalStorageService)
  {
    this.route.params.subscribe(params =>
      {
        this.current_patient_id = params['id']
        this.current_app_id = params['app_id']
      })
  }
  removeSp(_string:any)
  {
   return _string.replace("_"," ")
  }

  checkFeature(module:any)
  {
    return this.localstorage.checkFeatures(module);
  }
  cancelAppointmentfn(content:any,item:any)
  {
    this.modalService.open(content,  { size:'sm', scrollable: false,ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {  
      if (result === 'yes') {  
        let param = {
          patient_id:item.patient_id,
          app_id:item.app_id,
          status:"cancelled",
        }
        this.api.updateAppointment(param).subscribe({
          next:(res)=>{
            this.modalService.dismissAll();
            this.toast.success("Appointment Cancelled Successfully")
            this.getAppointmentById()
          },
          
          error:(e)=>{
    
          }
        })
      }  
    }, (reason) => {  

    });  
  }
  completeAppointmentfn(content:any,item:any,action:any)
  {
    this.modalService.open(content,  { size:'sm', scrollable: false,ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {  
      if (result === 'yes') {  
        let param = {
          patient_id:item.patient_id,
          app_id:item.app_id,
          status:action,
        }
        this.api.updateAppointment(param).subscribe({
          next:(res)=>{
            this.modalService.dismissAll();
            this.getAppointmentById()
            this.toast.success("Appointment Updated Successfully")
          },
          error:(e)=>{
    
          }
        })
      }  
    }, (reason) => {  

    });  
  }
  openRescedule(content:any,item:any)
  {
    this.appointment = item;
    let param = {
      where: {
        hospital_id:this.localStorage.getHospitalId(),
        user_id:this.appointment.user_id,
        center_id:this.localStorage.getCenterId(),
        dept_id:this.appointment.dept_id,
      },
      appointment_type:this.appointment.appointment_type,
      timezone: "Asia/Calcutta",
      dateFormat: "DD-MM-YYYY"
  }
      this.api.getAvailableDays(param).subscribe({
        next:(response)=>{
           console.log(response)
           this.DateList = response;
        },
        error:(e)=>{

        }
      })
    this.modalService.open(content,  { size:'sm', scrollable: false,ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {  
      if (result === 'yes') {  
       // this.deleteSchedule(item.schedule_id)
      }  
    }, (reason) => {  

    });  
  }
 
  loadSlots(event:any)
  {

    const date = event.target.value;
    if(date!="")
    {
      let param = {
        where: {
          hospital_id:this.localStorage.getHospitalId(),
          user_id:this.appointment.user_id,
          center_id:this.localStorage.getCenterId(),
          dept_id:this.appointment.dept_id,
        },
        appointment_type:this.appointment.appointment_type,
        appointment_date:date,
        timezone: "Asia/Calcutta",
        dateFormat: "DD-MM-YYYY"
    }
    console.log(param)
        this.api.getSlots(param).subscribe({
          next:(response)=>{
             console.log(response)
             this.TimeList = response;
          },
          error:(e)=>{
  
          }
        })
    }
    
    
  }
  getType(event:any)
  {

    
    const user_id = event.target.value;
    if(user_id)
    {
      let param = {
        where: {
          hospital_id:this.localStorage.getHospitalId(),
          user_id:user_id,
          center_id:this.localStorage.getCenterId(),
          dept_id:this.appointment.dept_id,
        }
    }
    this.api.getSchedule(param).subscribe({
      next:(response)=>{
       //console.log(response)
       const schedule = response.schedule;
       for (const key in schedule) {
         if (Object.prototype.hasOwnProperty.call(schedule, key)) {
           const element = schedule[key];
           if(element.isTrue)
           {
             this.appointmentTypeList.push(key)
           }
          // console.log(element)

         }
       }
      },
      error:(e)=>{
  
      }
     })
    }

  }
  loadDates(event:any)
  {

    const type = event.target.value;
    let lo = type.toLowerCase()
    //alert(lo)
    if(lo!=='walk_in')
    {
      let param = {
        where: {
          hospital_id:this.localStorage.getHospitalId(),
          user_id:this.appointment.user_id,
          center_id:this.localStorage.getCenterId(),
          dept_id:this.appointment.dept_id,
        },
        appointment_type:lo,
        timezone: "Asia/Calcutta",
        dateFormat: "DD-MM-YYYY"
   
   
      }
      this.api.getAvailableDays(param).subscribe({
        next:(response)=>{
           console.log(response)
           this.DateList = response;
        },
        error:(e)=>{

        }
      })
        
    }
    
    
  }
   openSelected(item:any)
   {
        let currentAppointment  = this.appointments.filter((item:any)=>{return item.app_id = this.app_id})
        return currentAppointment[0].appointment_date > item.appointment_date
   }
  
  loadDoctorsByDepartments(event:any)
  {
    let param = {
      hospital_id:this.localStorage.getHospitalId(),
      center_id:this.localStorage.getCenterId(),
      dept_id:event.target.value,
  }
  this.api.getUserByDepartmentSchedule(param).subscribe({
    next:(response)=>{
     console.log(response)
      this.DoctorDpList = response
    },
    error:(e)=>{

    }
   })
  }
  loadDepartments()
  {
    let param = {
      where:{
        hospital_id:this.localStorage.getHospitalId(),
        center_id:this.localStorage.getCenterId(),
        isPublic:true,
      }
    }
   this.api.getDepartments(param).subscribe({
    next:(response)=>{
     // console.log(response)
      this.departmentListDp = response
    },
    error:(e)=>{

    }
   })
  }
  ngAfterViewInit(){
    this.getAppointmentById();
}
getAppointmentById()
{
  this.loading = true
  let param = {
    patient_id:this.current_patient_id,
    hospital_id:this.localstorage.getHospitalId(),
    center_id:this.localstorage.getCenterId(),
  }
  this.api.getAllAppointmentByPatientId(param).subscribe({
    next:(res)=>{
      if(res)
      {
    
        this.count  = res.length
        this.currentTab = res.find((item:any)=>{return item.app_id == this.app_id})
        this.appointments = res.filter((item:any)=>{return item.app_id != this.app_id})
        this.loading = false
      }


    },
    error:(e)=>{
          this.loading = false
    }
  })  
}
OpenNewAppointment(content: TemplateRef<any>) {
  this.loadDepartments()
  this.offcanvasService.open(content, {  position: 'end' });
}

updateReschedule(isValid:any)
{
  this.isSubmittedresCheduled = true;
  let param = {
    patient_id:this.appointment.patient_id,
    app_id:this.appointment.app_id,
    appointment_date:this.appointment.appointment_date,
    appointment_time:this.appointment.appointment_time,
    status:"rescheduled"
  } 
  //console.log(param)
  if(isValid)
  {
    this.api.updateAppointment(param).subscribe({
      next:(res)=>{
        this.modalService.dismissAll();
        this.toast.success("Appointment Rescheduled Successfully")
        this.getAppointmentById()
      },
      error:(e)=>{

      }
    })
    console.log(this.appointment)
  }

}
saveAppointment(isValid:any)
{
  this.isSubmitted = true;
 

    if(isValid)
    {
      this.btnLoading = true;
      this.appointment.hospital_id = this.localStorage.getHospitalId()
      this.appointment.center_id = this.localStorage.getCenterId()
      this.appointment.check_in_type = "follow-up"
      this.appointment.patient_id = this.patient_id
      this.appointment.status = "booked"
      this.api.createAppointment(this.appointment).subscribe({
        next:(res)=>{
          this.btnLoading = false;
          this.toast.success("Followup Appointment created Successfully")
          this.getAppointmentById()
          this.offcanvasService.dismiss()
        },
        error:(e)=>{
          this.btnLoading = false;
        }
      })
      console.log(this.appointment)
    }
  
}
}


export class Appointment {
  app_id!: Number;
  user!:any;
  appointment_source:string = "webapp";
  appointment_type:string = "";
  appointment_date:string = "";
  appointment_time:string = "";
  status:string = "booked";
  user_id:string = "";
  patient_id:Number = 0;
  dept_id:string = "";
  client_id:string = ""
  check_in_type:string = "follow-up";
  opd_number:string = ""
  hospital_id:Number = 0
  center_id:Number = 0
}