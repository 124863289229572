import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LeadService } from '../services/lead.service';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-lead-form',
  templateUrl: './lead-form.component.html',
  styleUrls: ['./lead-form.component.scss']
})
export class LeadFormComponent {
  createFieldFormValue: any = {
    fields: {},
    lead_date: '',
    lead_time: '',
  };
  @ViewChild('leadForm')
  leadForm!: NgForm;
  isSubmitted: boolean = false;
  btnLoading: boolean = false;
  allLeadFeilds: any = [];
  fieldValues: any = {};
  copyOfFields: any = {};
  client: any;
  center: any;
  isFixedColumn:boolean = true
  dataLoading: boolean = false;
  successMessage: boolean = false;
  constructor(private localStorage:LocalStorageService, private api: LeadService,private route: ActivatedRoute, private toast: ToastrService) {
    this.client = this.route.snapshot.params['client'];
    this.center = this.route.snapshot.params['center'];
    this.getLeadsFields();

   }

  getLeadsFields() {
    // id = this.localStorage.getClientId()
    let param = {
      client_id:  this.client,
    };
    this.dataLoading = true;
    this.api.getLeadsFields(param).subscribe({
      next: (data) => {
        this.dataLoading = false;
        this.allLeadFeilds = data.fields;
        data.fields.forEach((element: any) => {
          if (element.sub_fields && element.sub_fields.length > 0) {
            this.fieldValues[element.field_name + '|subf'] = [];
          }
          let inc = 0;
          if (element.sub_fields && element.sub_fields.length > 0) {
            this.copyOfFields[element.field_name] = element.sub_fields.length;
            element.sub_fields.forEach((sub: any) => {
              this.fieldValues[element.field_name + '|subf'].push({
                [sub.field_name + '|' + inc]: '',
              });
              inc++;
            });
          }
        });
      },
      error: (e) => {
        this.dataLoading = false;
        console.error(e);
      },
    });
  }

  isValidEmail(email: string, form: any, key: any): boolean {
    const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      form.controls[key].setErrors({ invalid: true });
      return false;
    } else {
      form.controls[key].setErrors(null);
      return true;
    }
  }

  addMoreSubField(id: any, item: any) {
    let inc = item.sub_fields.length;
    const add = this.copyOfFields[item.field_name];

    // item.sub_fields.forEach((element: any) => {
    //   this.fieldValues[item.field_name + '|subf'].push({
    //     [element.field_name + '|' + inc]: '',
    //   });
    //   inc++;
    // });
    // this.allLeadFeilds[id].sub_fields.push(
    //   ...this.allLeadFeilds[id].sub_fields
    // );
    for (let i = 0; i < add; i++) {
      this.fieldValues[item.field_name + '|subf'].push({
        [item.sub_fields[i].field_name + '|' + inc]: '',
      });
      inc++;
    }
    this.allLeadFeilds[id].sub_fields.push(
      ...this.allLeadFeilds[id].sub_fields.slice(0, add)
    );
  }

  removeSubField(id: any, item: any, subInd: any) {
    const popLen = this.copyOfFields[item.field_name];
    this.fieldValues[item.field_name + '|subf'].splice(subInd, popLen);
    this.allLeadFeilds[id].sub_fields.splice(subInd, popLen);

    let inc = 0;
    this.fieldValues[item.field_name + '|subf'].forEach((element: any) => {
      const val = element;
      const preKey = Object.keys(element)[0];
      const preKeyVal = preKey.split('|');
      this.fieldValues[item.field_name + '|subf'][inc] = {
        [preKeyVal[0] + '|' + inc]: val[preKey],
      };
      inc++;
    });
  }

  
  scrollToError(e: any, container: HTMLElement) {
    if (!e.form.valid) {
      Object.keys(e.form.controls).forEach((key) => {
        if (e.form.controls[key].errors != null) {
          const el = document.getElementById(key);
          if (el) {
            const offset = el.offsetTop - 100;
            container.scrollTo({
              top: offset,
              behavior: 'smooth',
            });
            el.focus();
          }
        }
      });
    }
  }

  SaveLead(isValid: any) {
    this.isSubmitted = true;
    if (isValid) {
        this.isSubmitted = true;
        this.btnLoading = true;
        this.createFieldFormValue.client_id = this.client;
        this.createFieldFormValue.center_id = this.center;
        this.createFieldFormValue.fields = this.fieldValues;
        this.createFieldFormValue.lead_source = 'website';
        this.createFieldFormValue.lead_date = moment().format('YYYY-MM-DD');
        this.createFieldFormValue.lead_time = moment().format('HH:mm');
        this.api.addLeadData(this.createFieldFormValue).subscribe({
          next: (c) => {
            this.btnLoading = false;
            this.successMessage = true;
          },
          error: (e) => {
            this.btnLoading = false;
          },
          complete: () => {},
        });
      }
    }
}
