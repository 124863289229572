import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})

export class LocationService {
  constructor(private http: HttpClient) {}

  getCurrentLocation() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            if (position) {
              console.log(
                'Latitude: ' +
                  position.coords.latitude +
                  'Longitude: ' +
                  position.coords.longitude
              );
              let lat = position.coords.latitude;
              let lng = position.coords.longitude;

       
              const location = {
                lat,
                lng,
              };
              console.log(navigator.userAgent)
              resolve(location);
            }
          },
          (error) => {
            console.log(error)
            reject({code:error.code,message:error.message})
          }
        );
      } else {
        reject('Geolocation is not supported by this browser.');
      }
    });
  }
}