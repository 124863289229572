import { Component, EventEmitter, Output } from '@angular/core';
import moment from 'moment';
import { UsersService } from 'src/app/private/services/users.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import * as XLSX from 'xlsx';
interface ColumnMapping {
  [key: string]: string;
}
@Component({
  selector: 'app-excel-upload',
  templateUrl: './excel-upload.component.html',
  styleUrls: ['./excel-upload.component.scss']
})

export class ExcelUploadComponent {
  fileOver: boolean = false;
  show_table:boolean = false;
  upload_count:any = 0;
  lead_owners:any  = []
  possibleDateFormats: string[] = [
    'DD/MM/YYYY', 'MM/DD/YYYY', 'YYYY-MM-DD', 'DD-MM-YYYY', 'MM-DD-YYYY', 'YYYY/MM/DD',
    'DD/M/YYYY',
    'DD MMM YYYY', 'MMM DD, YYYY', 'MMMM DD, YYYY', 'DD MMMM YYYY'
  ];
// Define the column mapping
columnMapping: ColumnMapping = {
  'Contact Name': 'contact_name',
  'Country Code': 'country_code',
  'Contact Number': 'contact_phone',
  'Email': 'contact_email',
  'Organisation': 'company_name',
  'Designation': 'designation',
  'Sector': 'sector',
  'Sub Sector': 'sub_sector',
  'Country': 'country',
  'State': 'state',
  'City': 'city',
  'Lead Type':'lead_type',
  'Lead Status':'lead_status',
  'Source': 'lead_source',
  'Additional information': 'notes',
  'Deal Size':'deal_size',
  'Website Link':'website_link',  
  'Linkedin Link':'linkedin_link',  
  'Date':'lead_date',
  'Alternate Email':'alt_email',
  'Alternate Contact':'alt_contact'

  // Add more mappings as needed
};
isAdmin:any ;
currentUserId:any = ""
  @Output("exportDataEmitter") exportDataEmitter: EventEmitter<any> = new EventEmitter();
  @Output("sampleFile") sampleFile: EventEmitter<any> = new EventEmitter();
  constructor(private localStorage:LocalStorageService,private userApi:UsersService){
    this.getAllUsers()
    this.isAdmin = this.localStorage.checkFullAccessForTableList(
      this.localStorage.getRole()
    );
    this.currentUserId = this.localStorage.getUserId()
  }
  onFileDropped($event: any) {
    this.fileOver = false;
    this.handleFile($event[0]);
  }
  toggletable(){
    this.show_table = (this.show_table) ? false : true
  }
  downloadSampleExcel() {
    this.sampleFile.emit();
  }

  fileBrowseHandler(event: any) {
    const file = event.target.files[0];
    this.handleFile(file);
  }
  getAllUsers() {
    let param = {
      client_id: this.localStorage.getClientId(),
    };
    this.userApi.findAllUsers(param).subscribe({
      next: (res: any) => {
        this.lead_owners = res || [];
      },
      error: (e: any) => {
        console.error(e);
      },
    });
  }
  handleFile(file: File) {
    const fileReader = new FileReader();
    fileReader.onload = (e:any) => {
      const bufferArray = e.target.result;
      const workbook = XLSX.read(bufferArray, { type: 'binary' });
      const wsname = workbook.SheetNames[0];
      const ws = workbook.Sheets[wsname];
      let data:any = XLSX.utils.sheet_to_json(ws, { header: 1 });
      //data = data.filter((row: any[]) => row.some(cell => cell != null && cell !== ''));
      const mappedData = this.mapColumns(data);
 
      let finalData = this.transformLeads(mappedData)
      console.log("finalData",finalData)
      this.upload_count = finalData.length
      this.exportDataEmitter.emit(finalData)
    };
    fileReader.readAsArrayBuffer(file);
  }
  transformLeads(leads: any[]): any[] {
    // add fixed column septate
    return leads.map(lead => {
      const { contact_name, contact_phone, contact_email, company_name,lead_type,lead_source,lead_date,lead_time,lead_status,center_id,client_id,country_code,user_id, ...extraFields } = lead;
     let final_object = { contact_name, contact_phone, contact_email, company_name,lead_type,lead_source,lead_date,lead_time,lead_status,center_id,client_id,country_code,user_id, fields: extraFields };
    // console.log(final_object)
      return final_object;
    });
  }
  mapColumns(data: any[][]): any[] {
    const headers = data[0];
    const rows = data.slice(1);

    // Map the headers
    const mappedHeaders = headers.map(header => this.columnMapping[header] || header);

    // Map the rows
    const mappedRows = rows.map(row => {
      const mappedRow: any = {
        client_id:this.localStorage.getClientId(),
        center_id:this.localStorage.getCenterId(),
        user_id:this.currentUserId,
        lead_time:moment().format('HH:mm'),
        lead_date:moment().format('YYYY-MM-DD'),
      
      };
  
      row.forEach((cell, index) => {

        console.log(mappedHeaders[index] )
        if (typeof cell === 'number' && mappedHeaders[index] == 'lead_date') {
          cell =  this.formatDate(this.excelDateToJSDate(cell));
          mappedRow[mappedHeaders[index]] = cell;
       

        }
        else if (typeof cell === 'string' && mappedHeaders[index] == 'lead_date') {
          const date = this.parseDate(cell);
          console.log("date_dd"+date)
          //cell =  moment(cell, 'DD/MM/YYYY').format('YYYY-MM-DD');
          mappedRow[mappedHeaders[index]] = date!=undefined || date!="" ? date : moment().format('YYYY-MM-DD');
       

        }
       
       
        else {
          mappedRow[mappedHeaders[index]] = cell;
        }
          
         
      });
      //console.log()
      return mappedRow;
    });

    //console.log("mappedRows ",mappedRows)
    return mappedRows;
  }
  parseDate(dateString: string): string | null {
    for (const format of this.possibleDateFormats) {
      const parsedDate = moment(dateString, format, true);
      if (parsedDate.isValid()) {
        return parsedDate.format('YYYY-MM-DD');
      }
    }
    return null;
  }
  onFileOver(event: any) {
    this.fileOver = true;
  }

  onFileLeave(event: any) {
    this.fileOver = false;
  }
  formatDate(date: Date): string {
    return moment(date).format('YYYY-MM-DD');
  }
   excelDateToJSDate(serial: number): Date {
    // Excel date system starts on 1900/1/1 and assumes dates are serial numbers
    const startDate = new Date(Date.UTC(1899, 11, 30));
    return new Date(startDate.getTime() + serial * 86400000);
  }

  filterEmptyRows(data: any[][]): any[][] {
    return data.filter(row => row.some(cell => cell !== undefined && cell !== null && cell !== ''));
  }
  
}