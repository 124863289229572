const BASE_URL_A = "https://yghu6xgs02.execute-api.us-east-1.amazonaws.com/dev/"
const BASE_URL_B= "https://7l3rsnlkak.execute-api.us-east-1.amazonaws.com/dev/"


  // const BASE_URL_A = " https://xywfgrd3z1.execute-api.us-east-1.amazonaws.com/prod/"
  // const BASE_URL_B = " https://xywfgrd3z1.execute-api.us-east-1.amazonaws.com/prod/"
// const BASE_URL_A = "  http://localhost:4000/dev/"
// const BASE_URL_B = "  http://localhost:5000/"

// const BASE_URL_A = "https://m52aw18288.execute-api.us-east-1.amazonaws.com/prod/"
//const BASE_URL_A = "https://usyokb8dq7.execute-api.us-east-1.amazonaws.com/dev/"



export const environment = {
    production: false,

    //Auth 
    loginUser:BASE_URL_A+"loginUser",
    forgotPassword:BASE_URL_A+"forgotPassword",
    resetPassword:BASE_URL_A+"resetPassword",
    verifyOtp:BASE_URL_A+"verifyOtp",
    
    getAnalytics:"https://zt4vunflkg.execute-api.us-east-1.amazonaws.com/test/getAnalytics",

    //Leads
    // getAllLeads:BASE_URL+"getAllLeads",
    getLeadsRocord : BASE_URL_A+"getLeadRecord",
    getAllLeads :BASE_URL_A+"getLeads",
    // createLead:BASE_URL+"createLead",
    createLead: BASE_URL_A+"createLeads",
    getLead:BASE_URL_A+"getLead",
    updateLead: BASE_URL_A+"updateLeads",
    deleteLead: BASE_URL_A+"deleteLeads",
    createleadsfollowup: BASE_URL_A+"createleadshistory",
    getLeadsFollowup: BASE_URL_A+"getleadshistory",
    getLeadsFields: BASE_URL_A+"getLeadsField",
    addLeadField: BASE_URL_A+"createLeadsField",
    deleteLeadField: BASE_URL_A+"deleteLeadsField",
    updateLeadField: BASE_URL_A+"updateLeadsField",
    createBulkLead: BASE_URL_A+"createBulkLead",
    

    //Manage Users
    getAllUsers: BASE_URL_A+"getAllUsers",
    findAllUsers : BASE_URL_A+"getAllUserData",
    createUser: BASE_URL_A+"createUser",
    getUser:BASE_URL_A+"getUser",
    updateUser:BASE_URL_A+"updateUser",
    userModuleDropdownListing:BASE_URL_A+"userModuleDropdownListing",
    getUserByDepartment:BASE_URL_A+"getUserByDepartment",
    getUserByDepartmentSchedule:BASE_URL_A+"getUserByDepartmentSchedule",
    getAllCenter:BASE_URL_A+"getAllCenter",
    getAllDepartment:BASE_URL_A+"getAllDepartment",
    getAllRoles:BASE_URL_A+"getAllRoles",
  
    //Scheduled
    createSchedule:BASE_URL_A+"createSchedule",
    getSchedule:BASE_URL_A+"getSchedule",
    getAllSchedule:BASE_URL_A+"getAllSchedule",
    bulkSchedule:BASE_URL_A+"bulkSchedule",
    deleteSchedule:BASE_URL_A+"deleteSchedule",

    //Subscriber
    createSubscriberList: "https://e2cs94lgh8.execute-api.us-east-1.amazonaws.com/dev/createSubscriberList",
    createSubsciberList: "https://e2cs94lgh8.execute-api.us-east-1.amazonaws.com/dev/createSubscriberList",
    getSubsciberList: "https://e2cs94lgh8.execute-api.us-east-1.amazonaws.com/dev/getSubscriberlist",
    getAllSubsciberList: "https://e2cs94lgh8.execute-api.us-east-1.amazonaws.com/dev/getAllSubscriberList",
    deleteSubsciberList: "https://e2cs94lgh8.execute-api.us-east-1.amazonaws.com/dev/deleteSubscriberList",
    
    //Campaign
    createCampaignList: "https://e2cs94lgh8.execute-api.us-east-1.amazonaws.com/dev/createCampaignList",
    updateCampaignList: "https://e2cs94lgh8.execute-api.us-east-1.amazonaws.com/dev/updateCampaignList",
    getAllCampaignList: "https://e2cs94lgh8.execute-api.us-east-1.amazonaws.com/dev/getAllCampaignList",
    deleteCampaignList: "https://e2cs94lgh8.execute-api.us-east-1.amazonaws.com/dev/deleteCampaignList",

     //Contact
     createContact: "https://usyokb8dq7.execute-api.us-east-1.amazonaws.com/dev/createContact",
     updateContact: "https://usyokb8dq7.execute-api.us-east-1.amazonaws.com/dev/updateContact",
     getAllContact: "https://usyokb8dq7.execute-api.us-east-1.amazonaws.com/dev/getAllContact",
     deleteContact: "https://usyokb8dq7.execute-api.us-east-1.amazonaws.com/dev/deleteContact",

    //template
    getAllTemplates: "https://ekofx0jipf.execute-api.us-east-1.amazonaws.com/prod/getAllTemplates",

    //msg
    sendWhatsappMsg: "https://ekofx0jipf.execute-api.us-east-1.amazonaws.com/prod/sendWhatsappMsg",
    sendMediaTemplateMsg: "https://ekofx0jipf.execute-api.us-east-1.amazonaws.com/prod/sendMediaTemplateMsg",
    sendTextTemplateMsg: "https://ekofx0jipf.execute-api.us-east-1.amazonaws.com/prod/sendTextTemplateMsg",

    //Appointment
    getAllAppointmentWithCount:BASE_URL_A+"getAllAppointmentWithCount",
    createAppointment:BASE_URL_A+"createAppointment",
    getPatient:BASE_URL_A+"getPatient",
    getAllAppointment:BASE_URL_A+"getAllAppointment",
    getAllAppointmentByPatientId:BASE_URL_A+"getAllAppointmentByPatientId",
    getAvailableDays:BASE_URL_A+"getAvailableDays",
    getSlots:BASE_URL_A+"getSlots",
    updateAppointment:BASE_URL_A+"updateAppointment",
    checkInOpdPatientData:BASE_URL_A+"checkInOpdPatientData",

    //Email config
    createEmailConfig:BASE_URL_A+"createEmailConfig",
    getEmailConfig:BASE_URL_A+"getEmailConfig",
    updateEmailConfig:BASE_URL_A+"updateEmailConfig",
    
    // User Logs
    createUserLog:BASE_URL_A+"createUserLog",
    getUserLog:BASE_URL_A+"getUserLog",
    getAllUserLog:BASE_URL_A+"getAllUserLog",
    updateUserLog:BASE_URL_A+"updateUserLog",
    deleteUserLog:BASE_URL_A+"deleteUserLog",

    // Create Lead Space
    createLeadSpace:BASE_URL_A+"createLeadSpace",
    getAllLeadSpace:BASE_URL_A+"getAllLeadSpace",
    updateLeadSpace:BASE_URL_A+"updateLeadSpace",
    deleteLeadSpace:BASE_URL_A+"deleteLeadSpace",



    // evetn section start

    getAllEvents:BASE_URL_B+"getAllEvent",
    createEvent:BASE_URL_B+"createEvent",
    updateEvent:BASE_URL_B+"updateEvent",
    deleteEvent:BASE_URL_B+"deleteEvent",

    // event section end
  
    
  };
  
  
