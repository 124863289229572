import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ContactsService } from '../../services/contacts.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, map, merge, startWith, switchMap } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import { of as observableOf } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent implements AfterViewInit {
  form: any = 'create';
  btnLoading = false;
  deleteParam: any = {};
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  searchQuery = new FormControl();
  pageSizeOptions: number[] = [10, 25, 100];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  displayedColumns: string[] = [
    'fname',
    'lname',
    'email',
    'description',
    'phone',
    'address',
    'source',
    'status',
    'action',
  ];
  createContact: any = new Contact();

  constructor(
    private api: ContactsService,
    private localStorage: LocalStorageService,
    private modalService: NgbModal,
    private toast: ToastrService,
  ) {}

  ngAfterViewInit() {
    this.loadData();
  }

  loadData() {
    console.log('loadData');
    try {
      this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      console.log('this.paginator', this.paginator);
      merge(
        this.searchQuery.valueChanges,
        this.sort.sortChange,
        this.paginator.page
      )
        .pipe(
          startWith({}),
          switchMap(() => {
            let param = {
              where: {
                client_id: parseInt(this.localStorage.getClientId()),
              },
              page: this.paginator.pageIndex * this.paginator.pageSize,
              limit: this.paginator.pageSize,
              filter: this.searchQuery.value,
            };
            return this.api
              .getAllContacts(param)
              .pipe(catchError(() => observableOf(null)));
          }),
          map((response: any) => {
            if (response === null) {
              return [];
            }
            this.totalRows = response.count;
            return response;
          })
        )
        .subscribe({
          next: (data) => {
            console.log('data', data);
            this.dataSource = new MatTableDataSource<any>(data);
          },
          error: (e) => {
            console.error(e);
          },
          complete: () => {},
        });
    } catch (e) {
      console.error(e);
    }
  }

  editForm(element: any,content: any) {
    this.form = 'edit';
    this.createContact = element;
    this.modalService.open(content, {
      size: 'l',
      scrollable: true,
      centered: true,
    });
  }


  open(content: any) {
    this.form = 'create';
    this.createContact = new Contact();
    this.modalService.open(content, {
      size: 'l',
      scrollable: true,
      centered: true,
    });
  }

  create() {
    this.btnLoading = true;
    this.createContact.client_id = this.localStorage.getClientId();
    this.createContact.user_id = this.localStorage.getUserId();
    this.createContact.center_id = this.localStorage.getCenterId();
    this.api.createContact(this.createContact).subscribe({
      next: (res: any) => {
        this.btnLoading = false;
        this.modalService.dismissAll();
        this.toast.success('Contact created successfully');
        this.loadData();
      },
      error: (err: any) => {
        this.btnLoading = false;
        this.toast.error('Error creating contact');
      },
    });
  }

  update() {
    this.btnLoading = true;
    this.api.updateContact(this.createContact).subscribe({
      next: (res: any) => {
        this.btnLoading = false;
        this.modalService.dismissAll();
        this.toast.success('Contact updated successfully');
        this.loadData();
      },
      error: (err: any) => {
        this.btnLoading = false;
        this.toast.error('Error updating contact');
      },
    });
  }

  deleteModal(content: any, id: any) {
    let param = {
      contact_id: id,
      client_id: this.localStorage.getClientId(),
    };
    this.deleteParam = param;
    this.modalService.open(content, {
      size: 'l',
      scrollable: true,
      centered: true,
    });
  }

  deleteContact() {
    this.btnLoading = true;
    this.api.deleteContact(this.deleteParam).subscribe({
      next: (res: any) => {
        this.modalService.dismissAll();
        this.loadData();
        this.btnLoading = false;
        this.toast.success('Contact deleted successfully');
      },
      error: (err: any) => {
        console.error(err);
        this.btnLoading = false;
        this.toast.error('Error deleting contact');
      },
    });
  } 
}


export class Contact {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  description: string;
  phone: string;
  address: string;
  source: string;
  status: string;
  user_id: number;
  client_id: number;
  center_id: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  constructor() {
    this.id = 0;
    this.first_name = '';
    this.last_name = '';
    this.email = '';
    this.description = '';
    this.phone = '';
    this.address = '';
    this.source = '';
    this.status = '';
    this.user_id = 0;
    this.client_id = 0;
    this.center_id = 0;
    this.created_at = '';
    this.updated_at = '';
    this.deleted_at = '';
  }
}