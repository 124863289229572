import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { timeout, catchError } from 'rxjs/operators';
import { LocationService } from './location.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { UserlogService } from './userlogs.service';
import moment from 'moment';


@Injectable({ providedIn: 'root' })

export class TrackingService {
  public initialized = false;
  public coordinates:any =  {lat:"",lang:""};
  public locationDetails:any;
  public ip:any;
  private trackingUrl = '';

  constructor(
    private location: LocationService,
    private http: HttpClient,
    private localStorage:LocalStorageService,
    private api:UserlogService
  ) {
 
  }



  public track(actionName: string, _activity:string,desc:string,lead_id:any) {
    if(this.localStorage.getIsGetLocation()){
      this.http.get('https://ipinfo.io/json?token=800fb186becbfc').subscribe({
        next:(res:any)=>{
           // console.log(res)
          if(res){
            this.ip = res;
          }
        }
      })
    this.location.getCurrentLocation().then((res:any)=>{
        if(res){
           this.coordinates = res;
           this.http.get("https://nominatim.openstreetmap.org/reverse?format=json&lat="+this.coordinates.lat+"&lon="+this.coordinates.lng+"&zoom=18&addressdetails=1").subscribe({
               next:(res)=>{
                   if(res) {
                    this.locationDetails = res
                    let activity = {
                        user_id:this.localStorage.getUserId(),
                        lead_id:lead_id,
                        client_id:this.localStorage.getClientId(),
                        log_date: moment().format('YYYY-MM-DD'),
                        activity_log:{
                            action:actionName,
                            activity:_activity,
                            actionDetails:desc,
                            coordinates:this.coordinates,
                            address:this.locationDetails,
                            ip:this.ip?.ip,
                            city:this.ip?.city,
                            region:this.ip?.region,
                            country:this.ip?.country,
                            loc:this.ip?.country,
                
                        }
                    }
                    console.log(activity)
                    this.api.createUserLog(activity).subscribe({
                      next:(res)=>{
                        if(res) {
                          console.log(res)
                        }
                      }
                    })
                   }
               }
             });
        }   
       }).catch((e)=>{
        
        //console.log(this.ip)
           let cord = this.ip?.loc.split(",")
          if(cord !=undefined && cord.length > 0) {
            this.http.get("https://nominatim.openstreetmap.org/reverse?format=json&lat="+cord[0]+"&lon="+cord[1]+"&zoom=18&addressdetails=1").subscribe({
                next:(res)=>{
                    if(res) {
                     this.locationDetails = res
                     let activity = {
                    user_id:this.localStorage.getUserId(),
                    client_id:this.localStorage.getClientId(),
                    lead_id:lead_id,
                    log_date: moment().format('YYYY-MM-DD'),
                    activity_log:{
                        action:actionName,
                        activity:_activity,
                        actionDetails:desc,
                        coordinates:cord,
                        address:this.locationDetails,
                        ip:this.ip?.ip,
                        city:this.ip?.city,
                        region:this.ip?.region,
                        country:this.ip?.country,
                        loc:this.ip?.country,
            
                    }
                  }
    
                      console.log(activity)
                      this.api.createUserLog(activity).subscribe({
                        next:(res)=>{
                          if(res) {
                            console.log(res)
                          }
                        }
                      })
                    
                    }
                }
              }); 
          }
          
        
       });
    }
   
  
  
  }


}