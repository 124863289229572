import { Component, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ModalDismissReasons, NgbDatepickerModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, merge, startWith, switchMap } from 'rxjs';
import { UsersService } from 'src/app/private/services/users.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent {
[x: string]: any;
  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 25, 100];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  searchQuery = new FormControl();
  formAction = 'add'
  displayedColumns: string[] = ["username","fname",'lname',"email","role","date","status","action"];
  user:User = new User();
  @ViewChild("userForm")
  userForm!: NgForm;
  isSubmitted:boolean = false
  centers:any;
  roles:any;
  dateQuery = new FormControl();
  departmentsList:any;
  allUsers: any = [];
  deleteParam: any = {};
  newPassword:any = "";
  selectedRecord:any = {};
  filterArray: any = [
    {key : 'username', value : 'Username'},
    {key : 'fname', value : 'First Name'},
    {key : 'lname', value : 'Last Name'},
    {key : 'email', value : 'Email'},
    {key : 'role', value : 'Role'},
  ];
  
  
 
  constructor(private toast:ToastrService, private api:UsersService, private modalService: NgbModal, private localStorage:LocalStorageService){


  }
  ngOnInit(): void {
    //this.getAllUsers();
  }

  getDropdownList()
  {
    this.api.userModuleDropdownListing().subscribe({
      next:(res)=>{
      this.centers = res.centers || []
      this.roles = res.roles || []
      this.departmentsList = res.departments || []
      },
      error:(e)=>{

      }
    })
  }

  dateFilter(event: any) {
    this.dateQuery.setValue(event);
  }

  // getAllUsers() {
  //   let param = {
  //     where:{
  //       // center_id:this.localStorage.getCenterId(),
  //       client_id: parseInt(this.localStorage.getClientId())
  //     },
  //   };
  //   this.api.getAllData(param).subscribe({
  //     next: (res : any) => {
  //       this.allUsers = res || [];
  //       console.log(this.allUsers);
  //     },
  //     error: (e:any) => {
  //       console.error(e);
  //     },
  //   });
  // }

  deleteFieldModal(content: any,row: any) {
    let param = {
      ...row,
      status:"Suspended"
    }
    this.deleteParam = param;
    this.modalService.open(content, {
      size: 'l',
      scrollable: true,
      centered: true,
    });
  }

  deleteUser(){
    this.isLoading = true;
    this.api.updateUser(this.deleteParam).subscribe({
      next: (res) => {
        this.modalService.dismissAll();
        this.isLoading = false;
        this.toast.success("User Deleted Successfully", "Success");
       this.loadData();
      },
      error: (e) => {
        this.isLoading = false;
        this.toast.error(e.error.msg, "Error");
      }
    })
  }

  getCenters()
  {
    let param = {
      where:{
        client_id:this.localStorage.getClientId(),
      }
      
    }
    this.api.getAllCenter(param).subscribe({
      next:(res)=>{
      this.centers = res;
      }
    })
  }
  loadDepartments()
  {
    let param = {
      where:{
        // center_id:this.localStorage.getCenterId(),
        client_id:this.localStorage.getClientId(),
      }
      
    }
    this.api.getAllDepartment(param).subscribe({
      next:(res)=>{
      this.departmentsList = res;
      }
    })
  }

  loadRoles()
  {
    let param = {
      where:{
        // center_id: parseInt(this.localStorage.getCenterId()),
        client_id: parseInt(this.localStorage.getClientId())
      }
      
    }
    this.api.getAllRoles(param).subscribe({
      next:(res)=>{
        console.log("role",res)
      this.roles = res;
      }
    })
  }
  ngAfterViewInit()
  {
   this.loadData();
  }
  loadData()
  {
    try{
   this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
   merge(this.searchQuery.valueChanges, this.sort.sortChange, this.paginator.page).pipe(startWith({}),
   switchMap(()=>{
    let param = {
      where:{
        // center_id:this.localStorage.getCenterId(),
        client_id: parseInt(this.localStorage.getClientId())
      },
      page:(this.paginator.pageIndex *  this.paginator.pageSize),
      limit:this.paginator.pageSize,
      filter:this.searchQuery.value,
    }
    return  this.api.getAllData(param)
    .pipe(catchError(() => observableOf(null)));
   }),map((response:any)=>{
    if (response === null) {
      return [];
    }
 
    this.totalRows = response.count;
    return response
   })
   ).subscribe({
    next:(data) =>{
      console.log("Data->rows",data.rows)
    this.dataSource = new MatTableDataSource<any>(data.rows);
    },
    error:(e)=>{
     console.error(e)
    },
    complete:()=>{
     
    }
   })
  }
  catch(e){
   console.error(e)
  }
  }
  searchFilter(query:any)
  {
    this.searchQuery.setValue(query);
  }
  open(content:any)
  {
    setTimeout(() => {
      this.getCenters();
      setTimeout(() => {
        this.loadDepartments();
        setTimeout(() => {
          this.loadRoles();
        }, 1000); 
      }, 1000);  
    }, 1000);
    this.user = new User();
    this.formAction = "add";
		this.modalService.open(content, { size:'xl', scrollable: true})
	
  }
  edit(content:any,row:any)
  {
    setTimeout(() => {
      this.getCenters();
      setTimeout(() => {
        this.loadDepartments();
        setTimeout(() => {
          this.loadRoles();
        }, 1000); 
      }, 1000);  
    }, 1000);
    
   

    this.formAction = "edit";
    this.modalService.open(content, { size:'xl', scrollable: true})
    let param = {
      user_id:row.user_id,
      client_id:this.localStorage.getClientId()
    }
    this.api.getUser(param).subscribe({
      next:(res)=>{
       
         if(res.password){
          delete res.password
          this.user = res;
         }
      },
      error:(e)=>{

      }
    })

  }
  fnResetPassword(content:any,element:any){
    this.selectedRecord = element;
    console.log(element)
    this.modalService.open(content, { size:'md', scrollable: false})
  }
  saveNewPassword(){
    
    if(this.newPassword!=""){
      this.isLoading = true;
      let param = {
        password:this.newPassword,
        user_id : this.selectedRecord.user_id,
        client_id:this.selectedRecord.client_id
      }
      this.api.updateUser(param).subscribe({
        next:(res:any)=>{
         this.loadData();
         this.modalService.dismissAll();
         this.isLoading = false;
         this.toast.success("Password Updated Successfully",'Success')
        },
        error:(e:any)=>{  
          this.toast.error(e.error.msg,'Error')
          this.isLoading = false;
        }
       })
    }
    else {
      this.toast.error("Password Field should not blank")
    }
   

  }
  saveUser(isValid:any)
  {
    this.isSubmitted = true;
    if(isValid)
    {
      this.isLoading = true;
      this.user.client_id = this.localStorage.getClientId();
      this.user.center_id = this.localStorage.getCenterId(); 
      //add dept id
      if(this.formAction == 'add')
      {
       this.api.createUser(this.user).subscribe({
        next:(res)=>{
         this.loadData();
         this.isLoading = false;
         this.modalService.dismissAll();
         this.toast.success("User Added Successfully",'Success')
        },
        error:(e)=>{  
          this.isLoading = false;
          this.toast.error(e.error.msg,'Error')
        }
       })
      }
      else
      {
        this.api.updateUser(this.user).subscribe({
          next:(res:any)=>{
           this.loadData();
           this.modalService.dismissAll();
           this.isLoading = false;
           this.toast.success("User Updated Successfully",'Success')
          },
          error:(e:any)=>{  
            this.toast.error(e.error.msg,'Error')
            this.isLoading = false;
          }
         })
      }
    }
  }
}
function observableOf(arg0: null): any {
  throw new Error('Function not implemented.');
}

export class User {
  user_id!: Number;
  prefix: string = "";
  fname: string = "";
  lname: string = "";
  email: string = "";
  password!: JSON;
  username:String = "";
  gender:String = "";
  picture:String = "";
  profile:String = "";
  twoFactorAuthentication:String = "";
  status:String = "";
  // client_id:String = "";
  client_id:Number = 0;
  center_id:Number = 0;
  dept_id:Number = 0;
  role_id:Number = 0;
}
