import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Observable, catchError, tap, throwError } from "rxjs";
import { JWTTokenServiceService } from "../public/services/token.service";

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {

    constructor(private toastService:ToastrService,private tokenService:JWTTokenServiceService){}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token  = this.tokenService.getToken();
    //console.log(token)
    request = request.clone(
    {
        headers: request.headers.set('Authorization', `Bearer ${token}`)
    });
    return next.handle(request).pipe(
      tap({
        next: (event) => {
          if (event instanceof HttpResponse) {
            if(event.status == 200) {
                //console.log(event)
             // alert('Unauthorized access!')
            }
          }
          return event;
        },
        error: (error) => {

            let errorMsg = '';
            if (error.error instanceof ErrorEvent) {
               console.log('This is client side error');
               errorMsg = `Error: ${error.error.message}`;
               this.toastService.error(errorMsg, ``);
            } else {
         
               errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
               console.log(errorMsg);
              if(error.status === 401) {
                this.toastService.error( `Invalid User Login`,`Error Code : ${error.status}`);
              }
              else if(error.status === 404) {
                this.toastService.error( `Invalid Resource`,`Error Code : ${error.status}`);
              }
              else if(error.status === 500) {
                this.toastService.error( `Server Error`,`Error Code : ${error.status}`);
              }
              else if(error.status === 502) {
                this.toastService.error(`Server Error`,`Error Code : ${error.status}`);
              }
            }
            
         
        }
      }));
     
  }
}
