import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, map, merge, startWith, switchMap } from 'rxjs';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { of as observableOf } from 'rxjs';
import { ContactsService } from 'src/app/private/services/contacts.service';
import { LeadService } from 'src/app/private/services/lead.service';

@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.scss'],
})
export class WhatsappComponent {
  btnLoading = false;
  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  pageSizeOptions: number[] = [10, 25, 100];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  dataTable: MatTableDataSource<any> = new MatTableDataSource();
  leadDataSource: MatTableDataSource<any> = new MatTableDataSource();
  displayedLebel: any = {};
  temp: any = [];
  active = 'whatsapp';
  createActive = 'leads';

  searchQuery = new FormControl();

  displayedColumns: string[] = [
    'campaign_name',
    'campaign_group',
    'campaign_date',
    'is_schedule',
    'status',
    'action',
  ];

  displayedColumnsForCreate: string[] = [
    'fname',
    'lname',
    'email',
    'description',
    'phone',
    'address',
    'source',
    'status',
  ];

  displayedLeadColumns: string[] = [
  ];

  campaign = {};

  constructor(
    private modalService: NgbModal,
    private localStorage: LocalStorageService,
    private contactApi: ContactsService,
    private leadApi: LeadService
  ) {}

  ngOnInit(): void {}

  changeTab(type: any) {
    this.active = type;
  }

  loadData() {}

  edit(content: any, event: any) {}

  open(content: any) {
    this.modalService.open(content, { size: 'xl', scrollable: false });
    if(this.createActive === 'leads') {
      this.getLeadsFields();
    }
    this.addNewCampaign();
  }

  addNewCampaign() {
    try {
      this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      merge(
        this.searchQuery.valueChanges,
        this.sort.sortChange,
        this.paginator.page
      )
        .pipe(
          startWith({}),
          switchMap(() => {
            let param = {
              where: {
                client_id: parseInt(this.localStorage.getClientId()),
              },
              page: this.paginator.pageIndex * this.paginator.pageSize,
              limit: this.paginator.pageSize,
              filter: this.searchQuery.value,
              order : {}
            };
            
            if (this.createActive === 'leads') {
              param = {
                ...param,
                order: {
                  col: '"leadsrecord"."updatedAt"',
                  dir: 'DESC',
                },
              };
            }
            return this.createActive === 'leads'
              ? this.leadApi
                  .getAllData(param)
                  .pipe(catchError(() => observableOf(null)))
              : this.contactApi
                  .getAllContacts(param)
                  .pipe(catchError(() => observableOf(null)));
          }),
          map((response: any) => {
            if (response === null) {
              return [];
            }
            this.totalRows = response.count;
            return response;
          })
        )
        .subscribe({
          next: (data) => {
            if (this.createActive === 'leads') {
              this.leadDataSource = new MatTableDataSource<any>(data.rows);
            } else {
              this.dataTable = new MatTableDataSource<any>(data);
            }
          },
          error: (e) => {
            console.error(e);
          },
          complete: () => {},
        });
    } catch (e) {
      console.error(e);
    }
  }

  getLeadsFields() {
    let param = {
      client_id: this.localStorage.getClientId(),
    };
    this.leadApi.getLeadsFields(param).subscribe({
      next: (data) => {
        this.displayedLeadColumns = [];
        this.displayedLeadColumns.push('select');
        data.fields.forEach((element: any) => {
          this.displayedLeadColumns.push(element.field_name);
          this.displayedLebel[element.field_name] = element.label;
        });
        console.log('displayedColumns', this.displayedLeadColumns);
        console.log('displayedLebel', this.displayedLebel);
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  handleChangeTab(type: any) {
    this.createActive = type;
    if(this.createActive === 'leads') {
      this.getLeadsFields();
    }
    this.addNewCampaign();
  }

  selectAll(event: any) {
    if (event.checked == false) {
      console.log('false ------------');
      this.temp = [];
    }
    this.leadDataSource.data = this.leadDataSource.data.map((item) => ({
      ...item,
      isSelected: event.checked,
    }));
    if (event.checked == true) {
      console.log('true ------------');
      this.temp = this.leadDataSource.data;
    }
    console.log('-- ', this.temp);
  }
}
