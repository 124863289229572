import { Component, EventEmitter, Inject, Injector, Output, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { JWTTokenServiceService } from 'src/app/public/services/token.service';
import { LocationService } from '../../services/location.service';
import { TimerService } from 'src/app/utils/timer.service';
import { HttpClient } from '@angular/common/http';
import {
  getSupportedInputTypes,
  Platform,
  supportsPassiveEventListeners,
  supportsScrollBehavior,
} from '@angular/cdk/platform';
import { TrackingService } from '../../services/tracking.service';

const SERVICES: any[] = [TimerService];
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  providers: [SERVICES],
  styleUrls: ['./main.component.scss'],


})
export class MainComponent {
  @ViewChild('drawer', { static: true }) public drawer!: MatDrawer;
  @Output("toggleMenu") toggleMenu: EventEmitter<any> = new EventEmitter();
  isUserIdle: boolean = false;
  idleUserService = Inject(TimerService);
  constructor(private jwt:JWTTokenServiceService,
    private tracker:TrackingService,

  )
  {

    // //console.log(jwt.isTokenExpired())
    // this.location.getCurrentLocation().then((res:any)=>{
   
    //  if(res) {
    //   console.log(res)
    //  // let address:any;
    //   const addr  = this.http.get("https://nominatim.openstreetmap.org/reverse?format=json&lat="+res.lat+"&lon="+res.lng+"&zoom=18&addressdetails=1").subscribe({
    //     next:(res)=>{
    
    //         console.log(res)
    //     }
    //   });
    //  this.http.get('https://jsonip.com').subscribe({
    //   next:(res)=>{
    //     console.log(res)
    //   }
    //  })

    //  }
    // }).catch((e)=>{
    //  console.log(e)
    // })
  } 
  ngOnInit() {
    // this.idleUserService = this.injector.get(TimerService);
    // this.idleUserService.userInactive.subscribe(
    //   (isIdle: any) => (this.isUserIdle = isIdle)
    // );
 

  }
  

}
