import { Component, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { AppointmentService } from '../../services/appointment.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { merge, startWith, switchMap, catchError, map } from 'rxjs';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { UserlogService } from '../../services/userlogs.service';
import moment from 'moment';

@Component({
  selector: 'app-user-logs',
  templateUrl: './user-logs.component.html',
  styleUrls: ['./user-logs.component.scss']
})
export class UserLogsComponent {
  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 25, 100];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  appointment:Appointment =  new Appointment();
  @ViewChild(MatSort)
  sort!: MatSort;
  searchQuery = new FormControl();
  isSubmittedresCheduled:boolean = false;
  dateQuery = new FormControl();
  formAction = "add";
	closeResult = '';
  DateList:any = [];
  TimeList:any = [];
  filterArray:any = [];
  paymentDetail:any = {
    payment_status:"",
    remarks:""
  }
  @ViewChild("reappointmentForm")
  reappointmentForm!: NgForm;
  isSubmitted: boolean = false;
  exportArrayData:any = [];
  isOnlinePayment:boolean = false;
  displayedColumns: string[] = ["lead","date","user","ip","city","country","activity","type","desc","action"];
  exportColumns:any = [];
  constructor(private router:Router, private toast:ToastrService, private api:UserlogService,private modalService: NgbModal, private localStorage:LocalStorageService){
  }
  ngAfterViewInit() {
    this.loadData();
    this.filterArray.push({
      key:"ip",value:"Ip Address",
  
    },{
      key:"assign_to",value:"Assign To",
  
    })
 
  }
  
  dateFilter(event:any)
  {
   this.dateQuery.setValue(event)
  }
  
    // load Initial table data from based on source type
    loadData()
    {
      try{
     this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
     merge(this.dateQuery.valueChanges,this.searchQuery.valueChanges, this.sort.sortChange, this.paginator.page).pipe(startWith({}),
     switchMap(()=>{
      let param = {
        where:{client_id:this.localStorage.getClientId()},
        filter:this.searchQuery.value,
        page:(this.paginator.pageIndex *  this.paginator.pageSize),
        limit:this.paginator.pageSize,
        order:[{col:'id',dir:"desc"},{col:'log_date',dir:"desc"}],
        
      }
      if(this.dateQuery.value){param = {...param,...this.dateQuery.value}}
      let assign_to;
      console.log(this.searchQuery.value)
      if(this.searchQuery.value && this.searchQuery.value.hasOwnProperty('assign_to') ){
        assign_to = this.searchQuery.value['assign_to']
        //alert(assign_to)
        const obj = {
          ...param.where,
          user_id : assign_to,
        };
        param.where = obj;
        param.filter = null
        delete this.searchQuery.value['assign_to']
        
      }

      return  this.api.getAllUserLog(param)
      .pipe(catchError(() => observableOf(null)));
     }),map((response:any)=>{
      if (response === null) {
        return [];
      }
   
      this.totalRows = response.count;
      return response
     })
     ).subscribe({
      next:(data) =>{
     // console.log(data)
      this.exportArrayData = data.rows;
      this.dataSource = new MatTableDataSource<any>(data.rows);
      },
      error:(e)=>{
       console.error(e)
      },
      complete:()=>{
       
      }
     })
    }
    catch(e){
     console.error(e)
    }
    }
  searchFilter(query:any){

   // alert(query)
    this.searchQuery.setValue(query);
  }

  ago(updatedAt:any){
    return moment(updatedAt).fromNow()
  }
  
  
  
  export(header:any)
  {
    //console.log(header)
    let excelRowData:any = [];
    this.exportArrayData.forEach((element:any) => {
     let status = "";
     if( element.appointment.status=="booked")
     {
       status = element.appointment.status.replaceAll('booked','Booked')
     }
     else if( element.appointment.status=="check_in" )
     {
       status = element.appointment.status.replaceAll('check_in','Checked In')
     }
     else if( element.appointment.status=="rescheduled" )
     {
       status = element.appointment.status.replaceAll('rescheduled','Rescheduled')
     }
     else if( element.appointment.status=="cancelled")
     {
       status = element.appointment.status.replaceAll('cancelled','Cancelled')
     }
     else if( element.appointment.status=="no_show")
     {
       status = element.appointment.status.replaceAll('no_show','No Show')
     }
     
     if(this.isOnlinePayment)
     {
     excelRowData.push({0:element.pId,1:element.appointment.opd_number,2:element.fname+" "+element.lname,3:element.appointment.user.fname+" "+element.appointment.user.lname,4:element.appointment.appointment_date,5:element.appointment.appointment_time,6:element.appointment.check_in_type,7:element.appointment.appointment_type,8:element.appointment.appointment_source,9:element.appointment?.details?.payment_status || "",10:element.appointment?.details?.amount || "",11:status})
     } else
     {
      excelRowData.push({0:element.pId,1:element.appointment.opd_number,2:element.fname+" "+element.lname,3:element.appointment.user.fname+" "+element.appointment.user.lname,4:element.appointment.appointment_date,5:element.appointment.appointment_time,6:element.appointment.check_in_type,7:element.appointment.appointment_type,8:element.appointment.appointment_source,9:status})
     }

   });

    let excelData = [];
    let excelHeader = [];
      for (let i = 0; i < excelRowData.length; i++) {
        let element = excelRowData[i];
        let obj:any = {}
        for (let index = 0; index < header.length; index++) {
          let key = header[index]['key'];
          obj[key] = element[key]
        }
        excelData.push(obj)
      }
      for (let index = 0; index < header.length; index++) {
        let value = header[index]['value'];
        excelHeader.push(value)
      }
    console.log(excelData)
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, [excelHeader]);
    XLSX.utils.sheet_add_json(ws,excelData, { origin: 'A2', skipHeader: true });
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth()+1;
    let year = date.getFullYear();

  XLSX.writeFile(wb, "Appointments_data_"+day+"_"+month+"_"+year+"_"+date.getTime()+".csv");
  }


}
function observableOf(arg0: null): any {
  //throw new Error('Function not implemented.');
}

export class Appointment {
  appointment_id!: Number;
  user!:any;
  appointment_source:string = "webapp";
  appointment_type:string = "";
  appointment_date:string = "";
  appointment_time:string = "";
  status:string = "booked";
  user_id:string = "";
  patient_id:Number = 0;
  dept_id:string = "";
  check_in_type:string = "follow-up";
  opd_number:string = ""
  client_id:Number = 0
  center_id:Number = 0
}